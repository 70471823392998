const flatten = require('flat');
const messages = {
  GENERAL: {
    ACTIONS: 'Actions',
    ACTION: 'Action',
    ACTIVATE: 'Activate',
    ADD_MULTI_INPUT: 'Add an input',
    ADD: 'Add',
    ALL: 'All',
    ANY: 'Any',
    APPEND: 'APPEND',
    APPLICATIONS: 'Applications',
    APPLY: 'Apply',
    BACK: 'Back',
    BOTH: 'Both',
    BROWSE: 'Browse',
    CANCEL: 'Cancel',
    CASB_RULES: 'CASB Rules',
    CLEAR_FILTERS: 'Clear Filters',
    CONFIGURATION: 'Configuration',
    CONFIRM_CONTINUE: 'Are you sure you want to continue?',
    CONFIRM_DELETE: 'Are you sure you want to delete this?',
    CONFIRM_ERROR: 'The confirmation is incorrect',
    COPY: 'COPY',
    CREATED: 'Created',
    CTRL_CLICK: 'Press ctrl and click to select multiple',
    CUSTOM: 'Custom',
    DEFAULT: 'Default',
    DELETE_COMPLETELY: 'Delete Completely',
    DELETE_SMALL: 'Delete',
    DELETE_SUCCESS: 'Deleted successfully',
    DELETE: 'DELETE',
    DESCRIPTION_PLACEHOLDER: 'Description...',
    DESCRIPTION: 'Description',
    DETAILS: 'Details',
    DISCARD_SESSION: 'Are you sure, this will discard changes of other users!!',
    DLP: 'DLP',
    DOWNLOAD: 'Download',
    DUPLICATED_NAME: 'Name is already in use',
    INVALID_NAME: 'Name format is not valid',
    ERROR_NAME_LENGTH: 'Name length is not valid',
    ERROR_NAME_LENGTH_CUSTOM:
      'Name length must be between {min_length} and {max_length}',
    DYNAMIC_IP: 'Dynamic IP',
    EDIT_OBJECT: 'Edit Object',
    EDIT_SMALL: 'Edit',
    EDIT: 'EDIT',
    EMAIL: 'Email',
    ENTER_ADD: 'Add an uuid and press enter to add',
    ERROR: 'Error',
    EXPORT: 'Export',
    GENERAL: 'General',
    GENERATE: 'Generate',
    HOSTNAME: 'Hostname',
    IP: 'Ip',
    ID: 'ID',
    DOMAIN_NAME: 'Domain name',
    DOMAIN_NAME_PLACEHOLDER: 'video.google.com',
    SEARCH_ENGINE: 'Search engine',
    SEARCH_ENGINE_PLACEHOLDER: 'google',
    INFORMATION: 'Information',
    INSTALLING:
      'Installing configuration in {installation_tasks} device(s). Check the progress in the devices list',
    INSTANCE: 'Instance',
    INTERNAL_ERROR: 'Internal server error',
    INVALID: 'Invalid',
    INVALID_CONFIRM_PASSWORD: 'Password does not match',
    INVALID_DNS: 'DNS is not valid',
    MISSING_DNS: 'Add a valid DNS',
    INVALID_API_KEY: 'Invalid API key format',
    INVALID_EMAIL: 'Invalid email',
    INVALID_FIELD: 'Invalid field',
    INVALID_FORMAT_EMAIL: 'Invalid format email',
    INVALID_IP: 'IP is not valid',
    INVALID_DOMAIN_NAME: 'Domain name is not valid',
    INVALID_OBJECT_NAME: 'Should always start with characters',
    INVALID_PASSWORD:
      'The password must contain at least one capital letter and one number',
    INVALID_PORT: 'Port is not valid',
    INVALID_URL: 'Invalid URL format',
    LOAD_FILE: 'Load file',
    LOADING_MORE: 'Loading more..',
    LOADING: 'Loading',
    LOCKED: 'Locked',
    MAX_NAME_SIZE: 'Name must have less than 64 characters',
    MIN_LENGTH: 'Minimum length should be {length}',
    NAME_PLACEHOLDER: 'Name...',
    NAME: 'Name',
    NEXT: 'Next',
    NO_DATA: 'No data to display',
    NO_FILE: 'No file selected',
    NO: 'No',
    NONE: 'None',
    NOT_IN_USE: 'Not in use',
    NOW: 'Now',
    OK: 'Ok',
    OPTIONS: 'Options',
    OTHERS: 'Others',
    PENDING: 'Pending',
    PLACEHOLDER_SEARCH: 'Search...',
    PLACEHOLDER_URL: 'https://www.exaple.com',
    PREVIOUS: 'Previous',
    PROPERTY: 'Property',
    REFRESH: 'Refresh',
    REQUIRED_FIELD: 'Required field',
    RESET: 'RESET',
    RESTORING:
      'Restoring the last configuration in {installation_tasks} device(s). Check the progress in the devices list',
    RETURN: 'Return',
    SAVE_SMALL: 'Save',
    SAVE_SUCCESS: 'Saved successfully',
    SAVE: 'SAVE',
    SEARCH: 'Search',
    SELECT_FILE: 'Select file',
    SELECT: '-- Select an option --',
    SPECIFY: 'Specific',
    STATIC_IP: 'Static IP',
    STATUS: 'Status',
    SUCCESS: 'Success',
    SUPPORT: 'Contact support',
    TENANTS: 'Tenants',
    TIME: 'Time',
    TOTAL: 'TOTAL',
    TOTAL_SELECTED: 'Total selected',
    UNKNOWN: 'Unknown',
    UPDATE_SUCCESS: 'Updated successfully',
    UPDATE: 'UPDATE',
    UPLOAD: 'Upload',
    UPDATED: 'Updated',
    WARNING: 'Warning',
    WHERE_USED: 'Where it is used',
    WRONG_FILE_FORMAT: 'Incorrect file format',
    YES: 'Yes',
    DATA: 'Data',
    INVALID_UUID: 'Invalid UUID',
    DUPLICATED_UUID: 'Duplicated UUID',
    COMMENTS: 'Comments',
    DUPLICATED_INTERFACE_NAME: 'Interface name is already in use',
    FOUND_ERRORS: 'Errors found',
    NEXT_ERROR: 'Next error',
    PREVIOUS_ERROR: 'Previous error',
  },
  SIDEBAR: {
    HOME: 'Home',
    ANALYTIC: 'Analytic',
    DASHBOARD: 'Dashboard',
    ADMINISTRATION: 'Administration',
    DEVICES: 'Devices',
    DEVICE: 'Device',
    INSTANCE_CONFIGURATION: 'Instance Configuration',
    CONFIGURATION: 'Configuration',
    REPORTS: 'Reports',
    LOGS: 'Traffic logs',
    REPORT_TEMPLATES: 'Report templates',
    LOGS_REPORTS: 'Log & Report',
    SCHEDULE: 'Schedule',
    XDR: 'XDR',
    NO_TITLE: 'No title',
    USER_LOGINS: 'User logins',
  },
  NAVBAR: {
    LOGOUT: 'Logout',
    OBJECT_EXPLORER: 'Object Explorer',
    CHANGES: 'Changes',
    VIEW_CHANGES: 'View changes',
    INSTALL_CONFIGURATION: 'Install configuration',
    INSTALLING: 'Installing...',
    SUCCESS_INSTALL_CONFIGURATION: 'Configuration installed successfully',
    ERROR_INSTALL_CONFIGURATION: 'Error while installing configuration',
    WAIT_INSTALL_CONFIGURATION: 'Wait until the ongoing installation finishes',
    DISCARD_CHANGES: 'Discard changes',
    SUCCESS_DISCARD_CHANGES: 'Discarded changes successfully',
    CONFIRM_DISCARD_CHANGES: 'Do you want to discard the pending changes?',
    PROFILE: 'Profile',
    API_KEY: 'API KEY',
    NOTIFICATIONS: 'Notifications',
    VIEW_NOTIFICATIONS: 'View notifications',
    MANUAL: 'Manual',
  },
  ADMIN_USERS: {
    ACTIONS: 'Actions',
    ADD_TENANT: 'Add tenant',
    ADD_USER_ERROR: 'Error while creating user',
    ADD_USER_SUCCESS: 'User added sucessfully',
    ADD_USER: 'Add administrator',
    ADMINISTRATORS: 'Administrators',
    AZURE: 'Azure',
    BE_ACTIVE: 'Be Active',
    BE_ACTIVE_ALIAS: 'Alias',
    BE_ACTIVE_CONFIGS: 'Be Active Configurations',
    BE_ACTIVE_CONFIG_SAVE_SUCCESS: 'Be Active Configuration saved successfully',
    BE_ACTIVE_CONFIG_EDIT_SUCCESS:
      'Be Active Configuration updated successfully',
    BE_ACTIVE_CONFIG_ERROR: 'Error while saving Be Active Configuration',
    BE_ACTIVE_DELETE_CONFIG_SUCCESS:
      'Be Active Configuration deleted successfully',
    BE_ACTIVE_DUPLICATE_URL_USER: 'URL and User already in use',
    BE_ACTIVE_EDIT: 'Edit Be Active Configuration',
    BE_ACTIVE_INVALID_CREDENTIALS:
      'Invalid credentials. Check the configuration and try again',
    BE_ACTIVE_NEW: 'New Be Active Configuration',
    BE_ACTIVE_PASSWORD: 'Password',
    BE_ACTIVE_SUCCESS_CREDETIALS: 'Credentials are correct',
    BE_ACTIVE_SUCCESS_SYNC: 'Users synchronized successfully',
    BE_ACTIVE_SYNC_ERROR: 'Error while synchronizing users',
    BE_ACTIVE_TEST_CONNECTION: 'Test connection',
    BE_ACTIVE_TIME_SYNC_INFO_INVALID: 'Invalid time sync',
    BE_ACTIVE_TIME_SYNC_INFO: 'Range: 1-180 minutes',
    BE_ACTIVE_TIME_SYNC: 'Time Sync',
    BE_ACTIVE_URL_ERROR: 'URL is not valid',
    BE_ACTIVE_URL_PLACEHOLDER: 'https://beactive.com',
    BE_ACTIVE_URL_REQUIRED: 'URL is required',
    BE_ACTIVE_URL: 'URL',
    BE_ACTIVE_USER: 'User',
    BE_ACTIVE_SYNC_USERS: 'Synchronize users',
    CONFIRM_DELETE_TENANT: 'Are you sure you want to delete the tenant?',
    CONFIRM_DELETE: 'Are you sure you want to delete the user?',
    CONFIRM_ERROR: 'The confirmation is incorrect',
    CONNECTION_SETTINGS: 'Connection settings',
    DELETE_SUCCESS: 'User deleted successfully',
    DESCRIPTION: 'Users',
    EDIT_TENANT: 'Edit tenant',
    EMAIL_PLACEHOLDER: 'name@company.com',
    EMAIL: 'Email',
    FIRST_NAME: 'First Name',
    INACTIVE_TIME: 'Max inactive time (between 2 and 60 minutes)',
    LAST_NAME_PLACEHOLDER: 'Smith',
    LAST_NAME: 'Last Name',
    LOAD_ERROR: 'Error while loading Users',
    NAME_PLACEHOLDER: 'Anthony',
    NEW_USER: 'Add new user',
    PASSWORD_PLACEHOLDER: 'Welcome123$',
    PASSWORD: 'Password',
    PROTECTED: 'Protected',
    RECEIVE_MAILS: 'Receive mails',
    REPEAT_PASSWORD: 'Repeat Password',
    SERVER_CONNECTIONS: 'Server Connections',
    TENANT_ID: 'Tenant ID',
    TENANT_NAME: 'Tenant name',
    TENANT_TYPE: 'Tenant type',
    TYPE: 'Role',
    USER: 'User',
    WRONG_INACTIVE_TIME:
      'Max inactive time must be between {min} and {max} minutes',
    AZURE_AD_CONFIG: 'Azure AD Configuration',
    AD_TENANT_NAME: 'AD Tenant Name',
    AD_TENANT_ID: 'AD Tenant ID',
    AD_CLIENT_ID: 'AD Client ID',
    AD_CLIENT_SECRET: 'AD Client Secret',
    ADD_AZURE_AD_CONFIG: 'Add Azure AD Configuration',
    EDIT_AZURE_AD_CONFIG: 'Edit Azure AD Configuration',
    AD_CONFIG_SAVE_SUCCESS: 'Azure AD Configuration saved successfully',
    AD_CONFIG_EDIT_SUCCESS: 'Azure AD Configuration updated successfully',
    DELETE_AD_CONFIG: 'Are you sure you want to delete this configuration?',
    DELETE_AD_CONFIG_SUCCESS: 'Azure AD Configuration deleted successfully',
    AGENT_CONFIG_PULL_INTERVAL: 'VPN Client Config Pull Interval (minutes)',
    WRONG_MIN_CONFIG_INTERVAL:
      'Agent config interval must be higher than or equal to 1',
    WRONG_MAX_CONFIG_INTERVAL:
      'Agent config interval must be less than or equal to 99',
    WINDOWS_BUILD_CONFIRM:
      'Are you sure, you want to build the Windows installer?',
    WINDOWS_EXE_EMAIL_SUCCESS: 'Email sent successfully',
    WINDOWS_EXE_DOWNLOAD_SUCCESS: 'Windows exe downloaded successfully',
    SUCCESS_BUILD:
      'Build initiated successfully. The executable will be available in a few minutes. Please wait for the process to complete.',
    BUILD_STATUS:
      'A build is currently in progress. Please attempt the bulk creation after 5 minutes',
    PAGE_RELOAD: 'Please reload or refresh the popup to apply the changes',
    CONFIRM_LOG_AS_USER: 'Are you sure you want to log as this user?',
    ERROR_LOG_AS_USER: 'Error while logging as user',
    ERROR_NO_USER: 'No user found',
    ERROR_NOT_ALLOWED:
      'This user does not have permissions to log in as another user',
    ERROR_SAME_USER: 'You cannot log in as the same user',
    RESELLER_TENANT_SELECT: 'Select tenant',
    RESELLER_TENANT_IN_USE: 'At least one of the tenants is already in use',
    TENANT_HAS_RESELLER: 'has reseller',
  },
  ADMIN_IP_WHITELISTS: {
    IP_WHITELISTS: 'IP Whitelists',
    NAME: 'Name',
    ROLE: 'Role',
    ORIGIN: 'Origin',
    ERROR_FETCH: 'Error fetching whitelists',
    SUCCESS_DELETE: 'Whitelist deleted successfully',
    ERROR_DELETE: 'Error while deleting whitelist',
    SUCCESS_CREATE: 'Whitelist created successfully',
    ERROR_CREATE: 'Error while creating whitelist',
    SUCCESS_UPDATE: 'Whitelist updated successfully',
    ERROR_UPDATE: 'Error while updating whitelist',
    EDIT_IP_WHITELIST: 'Edit Ip Whitelist',
    ADD_IP_WHITELIST: 'Add Ip Whitelist',
    INVALID_WHITELIST: 'Invalid whitelist data',
    ERROR_INDEX: 'Error obtaining data',
    ACCOUNT: 'Tenant',
    ORIGIN_HELP: '* for Any, otherwise IPv4s separated by ;',
  },
  RESELLER_ACCOUNT: {
    DETELE_DEVICES_ERROR: 'Resellers with Cloud devices cannot be deleted',
  },
  ADMINISTRATION: {
    PERFORMANCE_MODE: 'Performance mode',
    PERFORMANCE_SETTINGS: 'PERFORMANCE SETTINGS',
    PERFORMANCE_WARNING: 'You are about to change performance mode',
    TITLE: 'Threat prevention',
    PROFILE_PROTECTION: 'Protection profile',
    TECHNOLOGIES: 'Technologies',
    SECURITY_PROTECTION:
      'Optimized security for users. Avoid cyberattacks. It includes protection for users who browse the web, work with desktop applications, access corporate applications, share files via FTP, or use other network protocols. This is the recommended profile for multiple protections in traffic between users and applications.',
    PROTECTION: 'protection',
    DESCRIPTION: 'Description',
    DETAILS: 'Details',
    'SSL-VPN': 'SSL VPN',
    'IPSEC-VPN': 'IPSEC VPN',
    'WEB-FILTER': 'Web Filter',
    'WEB-FILTER_DESCRIPTION':
      'Protect your business with a web filter and anti-malware. You can block access to websites by categories, countries and even block specific domains.',
    ANTIVIRUS: `Antivirus and Anti C&C`,
    SSL: 'SSL Inspection',
    IPS_GROUP: 'IPS Protection',
    IPS: 'IT Protection',
    OT: 'OT Protection',
    DLP: 'DLP',
    APPLICATIONS: 'Application control',
    CONFIGURATION: 'Configuration',
    ACTIVATE_PROTECTION: 'Activate protection',
    ANTIVIRUS_DESCRIPTION:
      "It prevents, detects and responds to all threats that may affect any of your organization's assets.",
    SSL_DESCRIPTION:
      'Decrypt SSL traffic to avoid encrypted threats entering applications or users leaving the Internet.',
    IPS_DESCRIPTION:
      'Intrusion detection and prevention system. Real-time traffic monitoring.',
    DLP_DESCRIPTION:
      'Apply rules to prevent sensitive information from leaving your organization on the Internet.',
    APPLICATIONS_DESCRIPTION:
      'Restrict access to specific applications in your organization.',
    ZTNA: 'ZTNA',
    ZTNA_DESCRIPTION: 'Connect with other computers',
    'REMOTE-ACCESS': 'Remote Access',
    'REMOTE-ACCESS_DESCRIPTION': 'Connect with other computers',
    ADVANCED_CONFIGURATION: 'Open advanced configuration',
    USER_ADMINISTRATION: 'Administrators',
    FIREWALL_DESCRIPTION: 'Firewall protection',
    ANTISPAM_DESCRIPTION: 'Antispam description',
    AUDIT: 'Audit',
    AUDIT_LOG_AS: '{impersonator} as {user}',
    TYPE: 'Type',
    ACTION: 'Action',
    DATE: 'Date',
    LICENSES: 'Licenses',
    LICENSE: 'License',
    LICENSE_GENERATOR: 'Licenses Generator',
    VALID_LICENSE: 'Valid License',
    INVALID_LICENSE: 'Invalid License',
    TIME_EXPIRED: 'Time expired',
    REMAINING_DAYS: 'Remaining days',
    VERSIONS: 'Versions management',
    FIREWALL: 'Firewall',
    SETTINGS: 'Settings',
    NETWORK: 'Network',
    THREAT_PREVENTION: 'Threat prevention',
    ANTISPAM: 'Antispam',
    COMMAND_AND_CONTROL: 'Command and control',
    OT_SIGNATURES: 'OT signatures',
    SSL_VPN: 'Vpn SSL',
    IPSEC_VPN: 'Ipsec VPN',
    CONTENT_CONTROL: 'Content control',
    CASB: 'CASB',
    CASB_DESCRIPTION: 'Configuration for CASB',
    'USER-IDENTIFICATION': 'User identification',
    'USER-IDENTIFICATION_DESCRIPTION': 'Configuration for user identification',
    'VPN-TUNNEL': 'VPN Tunnel',
    'VPN-TUNNEL_DESCRIPTION': 'Configuration for VPN Tunnel',
    'DNS-FILTER': 'DNS Filter',
    'DNS-FILTER_DESCRIPTION': 'DNS filter description',
    GLOBAL_SETTINGS: 'Global Settings',
    IP_WHITELISTS: 'IP Whitelists',
    CNM_SETTINGS: 'CNM Settings',
    CNM_CONNECTION: 'CNM Connection',
    API_KEY: 'API key',
    ENDPOINT: 'Endpoint',
    EMAIL_SETTINGS: 'Email Settings',
    INACTIVITY_SETTINGS: 'Inactivity Settings',
    NAT: 'NAT',
    LOOK_AND_FEEL: 'Look and feel',
    COLOR: 'Color',
    LOGO: 'Logo',
    REPORT_SETTINGS: 'Report Settings',
    AUDIT_SETTINGS: 'Audit Settings',
    ADD_USER_LOGS_AUDIT: 'Add user logs to the Audit',
    SYSLOG_SETTINGS: 'Syslog Settings',
    UPLOAD_CERTIFICATE: 'Upload certificate',
    VPN_USERS: 'Users',
    SERVER_ADDRESS: 'Server Address',
    PORT: 'Port',
    DOMAIN: 'Domain',
    AUTHENTICATION: 'Authentication',
    USERNAME: 'Username',
    PASSWORD: 'Password',
    NONE: 'None',
    PLAIN: 'PLAIN',
    LOGIN: 'Login',
    CRAM_MD5: 'CRAM MD5',
    PEER: 'Peer',
    OPENSSL: 'OpenSSL verify mode',
    STARTTLS: 'Enable and use STARTTLS',
    SMTP: 'Enable SMTP over TLS',
    CERTIFICATES: {
      TITLE: 'Certificates',
      INFO: 'The encrypted file must contain the certificate and the private key',
      PASSWORD_INFO: 'Optional parameter',
      ADD: 'Add certificate',
      EDIT: 'Edit certificate',
      SUBJECT: 'Subject',
      ISSUER: 'Issuer',
      CERTIFICATE_TYPE: 'Certificate type',
      CIPHERED: 'Ciphered',
      UNCIPHERED: 'Unciphered',
      PASSWORD: 'Password',
      EXPIRATION_DATE: 'Expiration date',
      VALIDITY: 'Validity in years',
      VALIDITY_WRONG:
        'The certificate validity must be between {min_year} and {max_year} years',
      SOURCE: 'Source',
      LOCAL: 'Local',
      CUSTOM: 'Custom',
      UPLOAD_CIPHERED_CERTIFICATE: 'Upload a PKCS#12 certificate',
      UPLOAD_CERTIFICATE: 'Upload a X-509 certificate',
      UPLOAD_KEY: 'Upload the private key of the certificate',
      ADD_ERROR: 'Error while adding the certificate',
      ADD_SUCCESS: 'Certificate added successfully',
      EDIT_ERROR: 'Error while updating the certificate',
      EDIT_SUCCESS: 'Certificate updated successfully',
      DELETE_ERROR: 'Error while deleting the certificate',
      DELETE_SUCCESS: 'Certificate deleted successfully',
      NAME_ERROR: 'The name is not valid',
      SOURCE_ERROR: 'The source is not valid',
      VALIDITY_ERROR: 'Validity is not valid',
      CERT_X509_ERROR: 'The certificate is not valid',
      KEY_RSA_ERROR: 'The key is not valid',
      VERIFY_ERROR: 'Certificate cannot be verified',
      FILE_ERROR: 'Certificate and key files cannot be empty',
      PKCS12_INVALID_PASSWORD: 'Incorrect file password',
      CONFIRM_DELETE: 'Are you sure you want to delete this certificate?',
      IN_USE: 'The certificate cannot be deleted because it is being used',
      NO_CA_ERROR: 'The certificate is not a Certificate Authority (CA)',
      ERROR_CLIENT: 'Error generating the client certificates',
      ERROR_SERVER: 'Error generating the server certificates',
      ERROR_DH: 'Error generating DH parameters',
    },
    VPN_SETTINGS: 'VPN Settings',
    AD_SETTINGS: 'AD Settings',
    AD_SYNC_INTERVAL: 'Sync users from AD (hours)',
    WRONG_MIN_CONFIG: 'AD Sync interval must be higher than or equal to 1',
    WRONG_MAX_CONFIG: 'AD Sync interval must be less than or equal to 24',
    LOGIN_SETTINGS: 'Login',
    ENABLE_2FA:
      'Enable Two Factor Authentication with an OTP App like Google Authenticator',
    CONFIRM_ENABLE_2FA:
      'This action could take several minutes. Do you wish to continue?',
    THREAT_INTEL: 'Teldat Threat Labs',
  },
  APPLICATIONS: {
    ALL_CATEGORIES: 'All categories',
    TABLE: {
      NAME: 'Name',
      DESCRIPTION: 'Description',
      ACTIONS: 'Actions',
      FAMILY: 'Category',
    },
    UNRANKED: 'Unranked',
    TITLE: 'Applications',
    REDIRECT_TO_APPLICATION: 'Go to Applications',
  },
  CNM_CONNECTION: {
    INFO: 'Only changeable if no devices are registered',
    TEST_CONNECTION: 'Test connection',
    SUCCESS: 'Successful connection',
    AUTHENTICATION_ERROR: 'Authentication error',
    CONNECTION_ERROR: 'Connection error',
    READ_TIMEOUT: 'Timeout on reading response',
    OPEN_TIMEOUT: 'Tiemout on opening connection',
    BAD_REQUEST: 'Bad request',
    UNKNOWN_HOST: 'Unknown host',
    EMPTY_VALUES: 'Empty values',
  },
  SSL_INSPECTION: {
    DOWNLOAD_CERTIFICATE_ERROR: 'Error while downloading Certificate',
    MODE_BASIC: 'Basic',
    MODE_FULL: 'Full',
    MODE: 'SSL Inspection Mode',
    SEARCH: 'Search',
    SELECT_YOUR_SSL: 'SELECT YOUR SSL INSPECTION MODE',
    ERROR_FETCH: 'Error while loading SSL Inspection options',
    SSL_EXCEPTION_ADD_ERROR: 'Error while adding SSL Exception',
    SSL_EXCEPTION_ADD_SUCCESS: 'SSL Exception added successfully',
    SSL_EXCEPTION_ADD: 'Add new SSL Exception',
    SSL_EXCEPTION_ANY: 'Any',
    SSL_EXCEPTION_CONFIRM_DELETE:
      'Are you sure you want to delete the SSL Exception?',
    SSL_EXCEPTION_DELETE_ERROR: 'Error while deleting SSL Exception',
    SSL_EXCEPTION_DELETE_SUCCESS: 'SSL Exception deleted successfully',
    SSL_EXCEPTION_EDIT_ERROR: 'Error while updating SSL Exception',
    SSL_EXCEPTION_EDIT_SUCCESS: 'SSL Exception updated successfully',
    SSL_EXCEPTION_EDIT: 'Edit SSL Exception',
    SSL_EXCEPTION_NAME_PLACEHOLDER: 'SSL Exception name...',
    SSL_EXCEPTION: 'SSL Exceptions',
    SSL_MODE_UPDATE_SUCCESS: 'SSL Inspection mode updated successfully',
    SSL_MODE_UPDATE_ERROR: 'Error while updating the SSL Inspection mode',
    CERTIFICATE_UPDATE_SUCCESS: 'Certificate updated successfully',
    CERTIFICATE_UPDATE_ERROR: 'Error while updating the certificate',
    CERTIFICATE_ERROR_FETCH: 'Error while loading the certificate options',
    TITLE: 'SSL Inspection',
    CERTIFICATE: 'Certificate',
    TABLE: {
      ID: 'ID',
      ACTION: 'Action',
      NAME: 'Name',
      SOURCE: 'Source',
      DESTINATION: 'Destination',
      STATUS: 'Status',
      ACTIONS: 'Actions',
    },
  },
  DLP: {
    ADD_RULE: 'Add new DLP rule',
    NAME: 'Name',
    REGEX: 'Regular expresion',
    RULES: 'Rules',
    TABLE: {
      NAME: 'Name',
      REGEX: 'Regular Expresion',
      ACTIONS: 'Actions',
      DESCRIPTION: 'Description',
    },
    DETAILS: 'Details',
    FILE_TYPES: 'File types',
    FILE_NAME: 'File name',
    FILE_NAMES: 'File names',
    CONTENT: 'Content',
    CONTENT_TOOLTIP: 'Match the following elements to files and traffic',
    FILE_TYPE_GROUPS: 'File type groups',
    FILE_NAME_GROUPS: 'File name groups',
    CONTENT_GROUPS: 'Content groups',
    FILE_TYPES_HELP: 'Please select the allowed file types',
    BASIC_MODE: 'Basic mode',
    REGEX_MODE: 'Regex mode',
    INSERT_BASIC_MODE: 'Insert file name',
    INSERT_REGEX_MODE: 'Insert file name regex',
    EXCLUDE_NAMES: 'Exclude files names',
    FILES: 'Files',
    HELP: 'Click for more regex information',
    PROTOCOL: 'Protocol',
    RULE_NAME: 'Rule name',
    ACTION: 'Action',
    OPTIONS: 'Options',
    EDIT_RULE_TITLE: 'Edit DLP rule',
    ADD_RULE_TITLE: 'Add DLP rule',
    CONFIRM_DELETE: 'Are you sure you want to delete this DLP rule?',
    SUCCESS_DELETE: 'DLP rule deleted successfully',
    FILE_TYPES_PLACEHOLDER: 'File types...',
    FILE_NAMES_PLACEHOLDER: 'File names...',
    RULE_NAME_MISSING: 'Please add a rule name',
    CRITERIA_MISSING: 'Please add at least one element to the rule',
    ADD_RULE_SUCCESS: 'DLP rule created successfully',
    EDIT_RULE_SUCCESS: 'DLP rule updated successfully',
  },
  USERS: {
    ADDITIONAL: 'Additional Information',
    CASB_ID: 'CASB ID',
    EMAIL: 'Email',
    GROUP: 'Group',
    NAME: 'Name',
    NEW_USER: 'Add New User',
  },
  NETWORK_USERS: {
    GENERATE_FILE_PROMPT:
      'To generate a file with the exported users from your Active Directory, execute the following command in a Powershell console in your Active Directory Manager Server',
    DESCRIPTION:
      'When the file is loaded, only the valid rows will be processed.',
  },
  USER_GROUPS: {
    NAME: 'Name',
    NEW_GROUP: 'Add group',
    USERS_LIST: 'Users list',
  },
  LOGS: {
    IPS_MODAL: {
      TYPE: 'Type',
      ACTION: 'Action',
      CATEGORY: 'Category',
      ALIAS_NAME: 'Device Name',
      SERIAL_NUMBER: 'Serial',
      SOURCE_IP: 'Source IP',
      SOURCE_PORT: 'Source Port',
      PROTOCOL: 'Protocol',
      DEST_IP: 'Destination IP',
      DEST_PORT: 'Destination Port',
      TX_BYTES: 'Transmitted Bytes',
      DATE: 'Date',
      SEVERITY: 'Severity',
      DESCRIPTION: 'Description',
      WEBFILTER_SUBCATEGORY: 'Web Filter Subcategory',
      URL: 'URL',
    },
    TABLE: {
      DATE: 'Date',
      EVENT: 'Event',
      SOURCE_IP: 'Source IP',
      SOURCE_PORT: 'Source Port',
      DEST_IP: 'Destination IP',
      DEST_PORT: 'Destination Port',
      PROTOCOL: 'Protocol',
      ACTION: 'Action',
      DESCRIPTION: 'Description',
      SERIAL: 'Serial',
      ALIAS_NAME: 'Device Name',
    },
  },
  PROFILE: {
    UPDATE_PORTS_SUCCESS: 'Ports updated successfully',
    UPDATE_PORTS_ERROR: 'Error updating ports',
    ADD_DEVICE_ERROR: 'Error while associating devices',
    ADD_DEVICE_SUCCESS: 'Devices associated successfully',
    ADD_DEVICES: 'Associate devices',
    ADD: 'Add new profile',
    API_KEY: 'Api key',
    CLIENT: 'Client',
    DEVICES: 'Devices',
    PORTS: 'Protocol ports',
    CONFIRM_DELETE: 'Are you sure you want to delete the profile?',
    CONFIRM_ERROR: 'The confirmation is incorrect',
    CONFIRM_REFRESH_API_KEY: 'Are you sure you want to refresh the api key?',
    DELETE_SUCCESS: 'Profile deleted successfully',
    DEVICES_WITH_FW_PROFILE:
      'Some devices have a different firewall profile installed',
    DEVICES_WITH_PROFILE: 'There are devices with an assigned profile',
    DUPLICATE_COPY: 'Copy',
    DUPLICATE_NAME: 'Enter duplicated profile name',
    DUPLICATE: 'Duplicate Profile',
    DUPLICATED_NAME: 'Name already registered',
    IN_USE: 'Unable to delete. Profile in use',
    INFO_PROFILE: 'Account details',
    MAXIMUM_ONE_CLOUD: 'Maximum of one firewall cloud profile',
    MAXIMUM_REACHED: 'Maximum number of profiles reached',
    MAXIMUM_UTM_REACHED: 'Maximum number of firewall UTM profiles reached',
    NAME_MAXIMUM_LENGTH: 'Only 16 characters are allowed for the profile name',
    NAME_PLACEHOLDER: 'Profile name',
    NAME: 'Enter new profile name',
    REFRESH_API_KEY_SUCCESS: 'Api key was refreshed successfully',
    SETTINGS: 'Profile settings',
    MISSING_LICENSES: 'Unavailable or expired licenses',
    ERROR_FETCH: 'Error while fetching the profiles',
    INVALID_SECURITY_FEATURES:
      'Low memory: The combination of security features {features} in profile {profile} are not supported in associated models RS420 (eg: {device})',
  },
  DEVICES: {
    ACTIONS: 'Actions',
    ADD_DEVICE_SUCCESS: 'Device added sucessfully',
    ADD_DEVICE: 'Add new device',
    ADD_SUBNETWORKS: 'Press Tab to add subnetworks',
    ADDRESSING_MODE: 'Addresing mode',
    ADVANCED: 'Advanced',
    ANTIVIRUS_PROFILE: 'Antivirus Profile',
    ANTI_SPOOFING: 'Anti Spoofing',
    ANTI_SPOOFING_HELP:
      'Turn on Source Address Verification to enable IP spoofing protection',
    ANTI_SPOOFING_INPUT: 'Enable:',
    APPLICATION_PROFILE: 'Application Profile',
    AUTHENTICATION: 'Authentication',
    AVAILABLE_LICENSES: 'Available Licenses',
    BATCH_HELP: 'Batch load',
    TUNNEL_HELP: 'Tunnel configuration',
    HELP: 'Device help',
    BATCH_LOAD: 'Batch load',
    CASB_PROFILE: 'CASB Profile',
    AUTO_IKE: 'Automatic IKE',
    CLI_EXTENSION: 'CLI Extension',
    COMPARE_BASE: 'Base',
    COMPARE_BASE_CLI: 'Cli base lines',
    COMPARE_TO: 'Compare to',
    COMPARE_TO_CLI: 'Cli compare lines',
    COMPARE_OPTION_CANDIDATE: 'Candidate',
    COMPARE_OPTION_CURRENT: 'Current',
    COMPARE_OPTION_ROLLBACK: 'Last working',
    CONECTION: 'Connection',
    CONECTION_INFO: 'Connection information',
    CONFIGURATION_NOT_SYNCHED: 'Configuration out of sync',
    CONFIGURATION_SYNCHED: 'Configuration synchronized',
    CONFIRM_DELETE: 'Are you sure you want to delete this device?',
    CONFIRM_DISABLE_DNS_FORWARDER:
      'This action may cause the deactivation of the DNS Forwarder. Would you like to disable it if possible?',
    CONFIRM_DISABLE_DNS_FORWARDER_ERROR: 'Error at disabling DNS Forwarder',
    KEEP_DNS_FORWARDER_ENABLED: 'Keep it enabled',
    CONNECTION_PROTOCOL: 'Protocol type',
    CONNECTION: 'Connection',
    CSV_DVC_EMPTY: 'The DVC in row {row} is empty',
    CSV_ERROR_CREATE: 'The creation of the device {name} returned an error',
    CSV_IP_DUPLICATED_FILE: 'The IP in row {row} is used in the same file',
    CSV_IP_DUPLICATED: 'The IP in row {row} is already use',
    CSV_IP_INVALID: 'The IP in row {row} is not valid',
    CSV_LICENSES_EMPTY: 'The Licenses in row {row} are empty',
    CSV_LICENSES_INVALID: 'The Licenses in row {row} are not valid',
    CSV_MODEL_EMPTY: 'The Model in row {row} is empty',
    CSV_MODEL_INVALID: 'The Model in row {row} is not valid',
    CSV_NAME_DUPLICATED_FILE: 'The Name in row {row} is used in the same file',
    CSV_NAME_DUPLICATED: 'The Name in row {row} is already in use',
    CSV_NAME_EMPTY: 'The Name in row {row} is empty',
    CSV_NOT_ENOUGH_COLUMNS: 'The number of columns in row {row} incorrect',
    CSV_SERIAL_EMPTY: 'The Serial Number in row {row} is empty',
    CSV_SERIAL_INVALID: 'The Serial Number in row {row} is not valid',
    CSV_TYPE_INVALID: 'The Type in row {row} is not valid',
    CSV_CORE_EMPTY: 'The Core Number in row {row} is empty',
    CSV_SHOW_INVALID: 'The Show in row {row} is not valid',
    CSV_NETWORKS_INVALID:
      'The Objects or Groups field of the row {row} must be filled in',
    CSV_PRE_SHARED_KEY_INVALID: 'The Pre Shared Key in row {row} is not valid',
    CSV_PEER_ID_INVALID: 'The Peer ID in row {row} is not valid',
    CONFIRM_EVENTS: 'Also delete logs related with this device',
    CONFIRM_ERROR: 'Error at deleting device',
    CORE_NUMBER: 'Number of cores',
    DELETE_SUCCESS: 'Device deleted successfully',
    DESCRIPTION_STEP1:
      'Device name and type are mandatory fields. Optional parameters can set them later.',
    DESCRIPTION_STEP2:
      'Device name and type are mandatory fields. Optional parameters can set them later.',
    DESCRIPTION_STEP4: 'Select one license to assign it to the device',
    DESCRIPTION: 'Devices list',
    DEVICE_ALREADY_EXISTS: 'Device alredy exist',
    DEVICE_NAME: 'Device name',
    DEVICES_GROUP: 'Group devices',
    DEVICES: 'Devices',
    DHCP_CONFIG: 'DHCP Server',
    DISMISS: 'Dismiss',
    DLP_PROFILE: 'DLP Profile',
    DUPLICATE_IP: 'IP already registered',
    DUPLICATE_NAME: 'Name already registered',
    DUPLICATE_SERIAL_NUMBER: 'Serial Number already registered',
    E_AUTH_TOKEN_ERROR: 'Unauthorized',
    EDIT_DEVICE: 'Edit device',
    EMPTY_DVC: 'Empty DVC',
    EMPTY_NAME: 'Empty Name',
    EMPTY_SERIAL: 'Empty Serial Number',
    EMPTY_UUID: 'Empty UUID',
    ERROR_CORE_NUMBER: 'Error while updating the number of cores',
    ERROR_EMPTY: 'Error: Campo vacío',
    ERROR_FIREWALL_DEPTH: 'Error while updating firewall engine quality',
    ERROR_INSTALLATION: 'Error while installing configuration',
    ERROR_PROFILE: 'There was an error while updating the profile',
    FEATURE_PROFILES: 'Feature profiles',
    FILTER: 'Devices',
    FIREWALL_CLI_HELP:
      "This code snippet has parameters between '<' and '>'. Customize them before pasting the configuration into your router.",
    FIREWALL_CLI: 'Firewall CLI',
    FIREWALL_DEPTH: 'Next generation firewall engine quality',
    FIREWALL_DEPTH_HIGH: 'Enhanced',
    FIREWALL_DEPTH_LOW: 'Default',
    SELECT_FIREWALL_PROFILE: 'Select firewall profile',
    GENERAL: 'General',
    GROUP_NAME: 'Group name',
    IMPORTED_SUCCESS: 'The file has been imported successfully',
    IN_PROCESS: 'Task in progress',
    SCHEDULED: 'Task scheduled',
    INCORRECT_FILE: 'Incorrect file',
    INSTALL: 'Install candidate configuration',
    INTERFACES: 'Interfaces',
    INVALID_CORE_NUMBER:
      'Invalid number of cores. It must be a number between 1 and 64.',
    INVALID_DVC: 'DVC is invalid',
    INVALID_IP: 'IP format is not valid',
    INVALID_HOSTNAME: 'Hostname format is not valid',
    INVALID_NAME: 'Name format is not valid',
    INVALID_RULE_PACK: 'Invalid rule pack',
    INVALID_PRE_SHARED_KEY:
      'Pre Shared Key format is not valid. Should be minimum 8 of the following characters: a-z A-Z 0-9 - + & ! @ # %% ^ * ( ) , . : _',
    NAME_TOO_LONG: 'Name length is more than 63 characters',
    NAME_TOO_SHORT: 'Name length is less than 2 characters',
    INVALID_UUID: 'UUID format is invalid',
    IP_ADDRESS: 'IP address',
    IP_DYNAMIC_EXTERNAL: 'External Dynamic IP service',
    IP_DYNAMIC_INTERNAL: 'Teldat Dynamic IP service',
    IP_HOSTNAME: 'IP / Hostname',
    IP_STATIC: 'Static IP',
    IP_TUNNEL: 'IP Tunnel',
    IPS_PROFILE: 'IPS Profile',
    LAST_CONNECTION: 'Last connection',
    LICENSE: 'License',
    MANUFACTERER: 'Manufacturer',
    MODEL: 'Model',
    MODEL_OTHER: 'Other',
    MONITOR: 'Monitor',
    NAME: 'Name',
    NETWORK: 'Network',
    NETWORKS: 'Networks',
    NO_PROFILE: 'No firewall profile assigned',
    NOT_FOUND: 'Device not found',
    OT_PROFILE: 'OT Profile',
    PROFILE: 'Profile Config',
    FIREWALL_PROFILE: {
      TITLE: 'Firewall Profile',
      CURRENT: 'Current Profile',
      MODAL_TITLE:
        'Select devices on which firewall profile {name} is to be installed',
    },
    PROFILES: {
      NONE: 'None',
      MODAL_TITLE: 'Select devices on which profile {name} is to be installed',
    },
    VPN_TUNNEL_PROFILE: 'VPN L2L Profile',
    VPN_getTitle: 'VPN Remote Access Profile',
    RECORDS: 'Records',
    RECORDS_HELP:
      'A default list of records will appear when associating a Web Filter profile which has Search protection enabled with the device Firewall profile',
    REGISTER: 'Add new device',
    RESOLVE_HOSTNAME: 'Resolve Hostname',
    RESOLVED_IP_ADDRESS: 'Resolved IP Address',
    RESTORE_DEVICE_CONFIG: 'Install last working configuratión',
    RESTORE_DEVICE_CONFIG_WARNING:
      'Returning the device to its previous configuration may cause the FW policies to not be applied correctly, leaving the device out of sync, it is important to review the latest changes to rules and functionality, and re-apply to synchronize the device.',
    RESTORE_DEVICE_CONFIG_EMPTY: 'Not found previous configuration to install',
    ROUTES: 'Routes',
    RULE_NAME: 'Rule Name',
    SERIAL_NUMBER: 'Serial number',
    SHARED_KEY: 'Pre Shared Key',
    SSL_INSPECTION_PROFILE: 'SSL Inspection Profile',
    STATIC_ROUTES: 'Static Routes',
    STATUS: 'Management Status',
    SUB_NETWORKS: 'Sub-networks',
    SUCCESS_CORE_NUMBER: 'Number of cores updated successfully',
    SUCCESS_FIREWALL_DEPTH: 'Firewall engine quality updated successfully',
    SUCCESS_PROFILE: 'Profile updated successfully',
    SYNCHED: 'Synchronized',
    SYSLOG: 'Syslog',
    SYSTEM_EVENTS: 'System Events',
    TOTAL_LICENSES: 'Total Licenses',
    TUNNEL: 'SASE Connection',
    TYPE: 'Device type',
    UPDATE_SUCCESS: 'Device updated successfully',
    WEB_FILTER_PROFILE: 'Web Filter Profile',
    DNS: 'DNS',
    DNS_FORWARDER: 'DNS FORWARDER',
    DNS_RESOLVER: 'DNS RESOLVER',
    DNS1: 'DNS primary',
    DNS2: 'DNS secondary',
    REQUIRED_INTERFACES: 'At least one interface is required',
    DNS_SAVED_SUCCESS: 'The DNS configuration has been saved successfully',
    DNS_SAVED_ERROR: 'Error while saving the DNS configuration',
    NO_MONITOR_DATA: 'No monitor data',
    DISCONNECTED_DEVICE: 'The device is not connected',
    GENERAL_CONFIGURATION: 'General config',
    RULE_PACK: 'IPS/WebFilter/Antivirus package',
    SUCCESS_RULE_PACK: 'IPS/WebFilter/Antivirus package updated correctly',
    ERROR_RULE_PACK: 'Error when updating the IPS/WebFilter/Antivirus package',
    STANDARD_PACKAGE: 'Standard',
    EXTENDED_PACKAGE: 'Extended',
    EXTREME_PACKAGE: 'Extreme',
    UNKNOWN_MEMORY_WARNING:
      'Unknown available memory, device free memory may not be enough for the selected rule pack',
    INVALID_MEMORY:
      'The following devices do not have enough memory for its selected packs',
    INVALID_AVAILABLE_MEMORY:
      'Selected rule package cannot be applied, available memory is not enough.\nFree memory: {available_memory} MB\nNecessary memory: {necessary_memory} MB',
    INVALID_TOTAL_MEMORY:
      'Selected rule package cannot be applied, device memory is not enough.\nTotal memory of {model}: {total_memory} MB\nNecessary memory: {necessary_memory} MB',
    CLI: 'CLI',
    DIFFERENCES: 'Show/Hide differences',
    DELETE_CNM_DEVICE: 'Delete device from CNM',
    CURRENT_CLI: 'Current configuration',
    NEW_CLI: 'New configuration',
    HARDWARE_OFFLOADING: 'Hardware Offloading',
    SUCCESS_HARDWARE_OFFLOADING: 'Hardware Offloading updated correctly',
    ERROR_HARDWARE_OFFLOADING: 'Error when updating the Hardware Offloading',
    CLOUD_STATUS: 'Cloud status',
    HOSTNAME: 'Hostname',
    NEVER_CONNECTED: 'Never connected',
    PEER_ID: 'IKE Peer ID',
    PRESHARED_KEY: 'Pre-shared key',
    SASE_TUNNEL_STATUS: 'SASE Tunnel Status',
    TUNNEL_UP: 'Up',
    TUNNEL_FULL_UP: 'Full Up',
    TUNNEL_DOWN: 'Down',
    VALIDATE_LENGTH_IKE_PEER_ID:
      'IKE Peer ID must be between 6 and 16 characters',
    REMOTE_ACCESS_SSL_VPN: 'SSL VPN Profile',
    UTM_LICENSE_EXCEPTION:
      'Error while installing configuration. UTM license <{license}> missing or expired for device <{device}> in profile <{profile}>',
    CLOUD_LICENSE_EXCEPTION:
      'Error while installing configuration. Cloud license <{license}> missing or expired in account for profile <{profile}>',
    INTERFACE_EXCEPTION:
      'Error while installing configuration. Interface <{interface}> is not present for device <{device}> in profile <{profile}>',
    UTM_PROFILE_EXCEPTION:
      'Error while installing configuration. No profile configured in device <{device}>',
    TASK_EXCEPTION: '{message}',
    NO_ZTNA_LICENSE: 'No ZTNA license available',
    CONNECTOR_NAME: 'Connector name',
    ZTNA_TUNNEL_STATUS: 'ZTNA Tunnel status',
  },
  INTERFACES: {
    ADD: 'Add interface',
    ADDRESS_RANGE: 'Address Range',
    ADDRESSING_MODE: 'Addressing Mode',
    AUTOMATIC: 'Automatic (dhcp)',
    CONFIRM_DELETE_INTERFACE: 'Do you want to delete the interface?',
    CONFIRM_CLEAN_INTERFACE: 'Do you want to clean the interface?',
    DEFAULT_ROUTER: 'Default Router',
    DESCRIPTION: 'Description',
    DESCRIPTION_PLACEHOLDER: 'Description',
    DESCRIPTION_MAX_VALUE:
      'Only a maximum of {maxValue} characters are allowed',
    DHCP_SERVER: 'DHCP Server',
    DHCP: 'DHCP',
    DUPLICATED_IP: 'IP is already in use',
    DUPLICATE_VLAN_ID: 'VLAN id is already in use, within the Ethernet',
    EDIT: 'Edit interface',
    EMPTY_NAME: 'Empty Name',
    END_IP: 'End IP',
    ERROR_CREATE: 'Error while creating interface',
    ERROR_CLEAN: 'Error while cleaning interface',
    ERROR_DELETE: 'Error while deleting interface',
    ERROR_DELETE_ETHERNET: 'Cannot be deleted, Ethernet interface type',
    ERROR_FETCH: 'Error while fetching the interfaces',
    ERROR_UPDATE: 'Error while updating interface',
    ETHERNET_BRIDGE_LIST: 'List of Ethernet Interfaces without configuration',
    ETHERNET_JUST_VM: 'An Ethernet interface cannot be created on this device.',
    GENERAL: 'General',
    ID: 'ID',
    INTERFACE: 'Interface',
    INTERFACES: 'Interfaces',
    INVALID_RANGE_BRIDGE_ID: 'Range should between 0 and 1000',
    INVALID_RANGE_VLAN_ID: 'Range should be between 1 and 4094',
    INVALID_RANGE_ETH_ID: 'Range should between 0 and 1000',
    IP_MASK: 'IP Address / Mask',
    IP: 'IP',
    MANUAL: 'Manual',
    NAME: 'Name',
    PRIMARY_DNS_SERVER: 'Primary DNS Server',
    RANGE: 'Start IP / End IP',
    REQUIRE_SUBNET: 'Subnet is required',
    SECONDARY_DNS_SERVER: 'Secondary DNS Server',
    SECURITY: 'Security',
    START_IP: 'Start IP',
    STATUS: 'Status',
    SUBNETWORK: 'Subnetwork',
    SUCCESS_CREATE: 'Interface created successfully',
    SUCCESS_CLEAN: 'Interface cleaned successfully',
    SUCCESS_DELETE: 'Interface deleted successfully',
    SUCCESS_UPDATE: 'Interface updated successfully',
    TYPE: 'Interface type',
    BRIDGE_NAME_DUPLICATED: 'The Bridge is already in use',
    VLAN_NAME_DUPLICATED: 'The VLAN is already in use',
    ETHERNET_NAME_DUPLICATED: 'The Ethernet is already in use',
    ETHERNET_NAME_VALID:
      'Make sure the interface name matches an available interface on the device',
    BRIDGE_IP_DUPLICATED: 'The IP is already in use by another Bridge',
    VLAN_IP_DUPLICATED: 'The IP is already in use by another VLAN',
    ETHERNET_IP_DUPLICATED: 'The IP is already in use by another Ethernet',
  },
  FILTERS: {
    MORE: 'More',
    PAST_5MIN: 'Past 5 minutes',
    PAST_HOUR: 'Past hour',
    PAST24HOURS: 'Past 24 hours',
    PAST30DAYS: 'Past 30 days',
    PAST5YEARS: 'Past 5 years',
    PAST7DAYS: 'Past 7 days',
    PASTYEAR: 'Past year',
    RANGE: 'Custom Range',
    TODAY: 'Today',
    YESTERDAY: 'Yesterday',
    ACTION: {
      ALERT: 'Alert',
      ALLOWED: 'Allowed',
      BLOCKED: 'Blocked',
      DROP: 'Drop',
      NAME: 'Action',
      PASS: 'Pass',
      DETECT: 'Detect',
      PERMIT: 'Permit',
      BLOCK: 'Block',
      CUSTOM: 'Custom',
      OFF: 'Off',
      DEFAULT: 'Default',
    },
    ADD_MULTI_INPUT: {
      SRC_IP: 'Add a network or a network range',
    },
    APPLICATION: {
      NAME: 'Application',
    },
    APPLICATIONS: {
      NAME: 'Applications',
      SAFARI: 'Safari',
    },
    BRIGHTCLOUD_ID: {
      NAME: 'Subcategory',
    },
    FAMILY: {
      NAME: 'Family',
    },
    CATEGORY: {
      NAME: 'Category',
    },
    CREATED_AT: {
      NAME: 'Create at',
    },
    DATE: {
      'LAST DAY': 'Last day',
      'LAST MONTH': 'Last Month',
      'LAST WEEK': 'Last Week',
      CUSTOM: 'Custom',
      NAME: 'Date',
      TODAY: 'Today',
      YESTERDAY: 'Yesterday',
    },
    DESCRIPTION: {
      NAME: 'Description',
    },
    DEST_COUNTRY_CODE: {
      NAME: 'Destination country',
      '-': 'Unknown',
    },
    DEST_COUNTRY_NAME: {
      NAME: 'Country',
    },
    DEST_IP: {
      NAME: 'Destination IP',
    },
    FEATURE: {
      ANOMALY: 'Anomaly',
      AV: 'Antivirus',
      APPLICATIONS: 'Applications',
      APPS: 'Applications',
      DLP: 'DLP',
      IPS: 'IPS',
      NAME: 'Feature',
      VPN: 'VPN',
      WEBFILTER: 'Web Filter',
    },
    FILE_NAME: {
      NAME: 'File name',
    },
    FILE_SIZE: {
      NAME: 'File size',
    },
    FILE_MAGIC: {
      NAME: 'File type',
    },
    HTTP_METHOD: {
      DELETE: 'DELETE',
      GET: 'GET',
      NAME: 'HTTP Method',
      POST: 'POST',
      PUT: 'PUT',
    },
    OS: {
      ANDROID: 'Android',
      IOS: 'iOS',
      LINUX: 'Linux',
      MACOS: 'macOS',
      NAME: 'OS',
      WINDOWS: 'Windows',
    },
    PERIOD: {
      DAY: 'Day',
      HOUR: 'Hour',
      MINUTE: 'Minute',
      MONTH: 'Month',
      NAME: 'Period',
      SECOND: 'Second',
      WEEK: 'Week',
      YEAR: 'Year',
    },
    PROTO: {
      ICMP: 'ICMP',
      IGMP: 'IGMP',
      NAME: 'Protocol',
      TCP: 'TCP',
      UDP: 'UDP',
    },
    PROTOCOL: {
      ICMP: 'ICMP',
      IGMP: 'IGMP',
      NAME: 'Protocol',
      TCP: 'TCP',
      UDP: 'UDP',
    },
    RECEIVED: {
      NAME: 'Received bytes',
    },
    REPUTATION: {
      NAME: 'Reputation',
    },
    SEVERITY: {
      1: 'Critical',
      2: 'Major',
      3: 'Minor',
      4: 'Audit',
      5: 'Informational',
      AUDIT: 'Audit',
      CRITICAL: 'Critical',
      INFORMATIONAL: 'Informational',
      MAJOR: 'Major',
      MINOR: 'Minor',
      NAME: 'Severity',
    },
    SRC_COUNTRY_CODE: {
      NAME: 'Origin country',
    },
    SRC_IP: {
      INVALID: 'An invalid network has been detected at the source IP filter',
      NAME: 'Source IP',
    },
    TARGET: {
      BOTH: 'Both',
      NAME: 'Target',
      TO_CLIENT: 'To client',
      TO_SERVER: 'To server',
    },
    TRANSMITTED: {
      NAME: 'Transmitted bytes',
    },
    TYPE: {
      ANTIVIRUS: 'Antivirus',
      APPLICATIONS: 'Applications',
      CUSTOM: 'Custom',
      DEFAULT: 'Default',
      IPS: 'IPS',
      NAME: 'Type',
    },
    TYPE_IPS: {
      CUSTOM: 'Custom',
      DEFAULT: 'Default',
      NAME: 'Type',
    },
    UPDATED_AT: {
      NAME: 'Update at',
    },
    URL: {
      NAME: 'URL',
    },
    RESULT: {
      NAME: 'Result',
      SUCCESS: 'Success',
      FAIL: 'Failed attempt',
    },
  },
  DASHBOARD: {
    TOP_PROTECTION: 'Top Events',
    FEATURES: 'Features',
    BLOCKED: 'Blocked',
    TRAFFIC_BLOCKED: 'Blocked Traffic',
    TRAFFIC_ALLOWED: 'Allowed Traffic',
    MALWARE_REGIONS: 'Malware Blocked by Region',
    SEVERITY_ALERTS: 'Events by Severity',
    FEATURE_ALERTS: 'Events by Feature',
    NAME: 'Name',
    SEVERITY: 'Severity',
    DETECTIONS: 'Detections',
    TRAFFIC_STATS: 'Traffic Statistics',
    ACTION: 'Action',
    BY: 'By',
    ALERTS: 'Events',
    TRAFFIC: 'Traffic',
    SEVERITY_BY_SRC_IP: 'Severity by Source IP',
    SEVERITY_BY_DST_IP: 'Severity by Destination IP',
    TRAFFIC_BY_ACTION: 'Traffic by Action',
    TOP_TEN_EVENTS_BY_SRC_IP: 'Top 10 Events by Source IP',
    TOP_TEN_EVENTS_BY_DEST_IP: 'Top 10 Events by Destination IP',
    XDR_EVENTS: 'XDR Events',
    TRAFFIC_BY_APPLICATION_AND_IP: 'Traffic by Application and Source',
    DLP_EVENTS: 'DLP Events',
    TOP_TEN_DLP_EVENTS_BY_FILE_NAME: 'Top 10 Events by File Name',
  },
  CONFIGURATION: {
    IpsProtection: 'IPS Protection',
    Ssl: 'SSL',
    Dlp: 'DLP',
    Applications: 'Application Control',
    RULES: 'Rules list',
    Vpn: 'VPN',
    WebFilter: 'Web Filter',
    Antivirus: 'Antivirus',
    OtProtection: 'IPS OT Protection',
    PENDING_CHANGES: 'Changes to persist',
    AFFECTED_DEVICES: 'Affected devices',
    SUMMARY_CHANGES: 'Summary changes you want to install:',
    CONFIRMATION_QUESTION: 'Do you want to apply these changes?',
    CASB: 'CASB',
    CASB_MISSING_SSL:
      'SSL Inspection profile is needed for CASB to work correctly',
    CASB_SSL_FULL_REQUIRED:
      'SSL Inspection Profile set up to "FULL" should be added for CASB to work correctly',
  },
  VPN: {
    ACTIVATE_IPSEC: 'Activate IPsec VPN',
    ACTIVATE_SSL: 'Activate SSL VPN',
    GROUPS: 'Vpn Groups',
  },
  IPS_PROTECTION: {
    IPS_IDS_PROTECTION: 'IPS/IDS Protection',
    IDS_PROTECTION: 'IDS Protection',
    IPS_PROTECTION: 'IPS Protection',
    ANTIDOS_PROTECTION: 'AntiDoS Protection',
    TABLE: {
      DESCRIPTION: 'Description',
      SEVERITY: 'Severity',
      OS: 'OS',
      ACTION: 'Action',
      AFFECTED_APPS: 'Affected Applications',
      CVE_ID: 'CVE ID',
      UPDATED_AT: 'Updated at',
      CREATED_AT: 'Created at',
      TARGET: 'Target',
      TYPE: 'Type',
      RULE_PACK: 'Rule pack',
      ACTIONS: 'Actions',
    },
    ANTIDOS_TABLE: {
      NAME: 'Name',
      STATUS: 'Status',
      ACTION: 'Action',
      THRESHOLD: 'Threshold',
      COUNT: 'Count',
      INTERVAL: 'Interval',
    },
    ANTIDOS_FORM: {
      ADD_RULE: 'Add new AntiDoS rule',
      ADD_SUCCESS: 'AntiDoS rule added successfully',
      ADD_ERROR: 'Error while adding AntiDoS rule',
      EDIT_RULE: 'Edit AntiDoS rule',
      EDIT_SUCCESS: 'AntiDoS rule updated successfully',
      EDIT_ERROR: 'Error while updating AntiDoS rule',
      CONFIRM_DELETE: 'Are you sure you want to delete the AntiDoS rule?',
      DELETE_SUCCESS: 'AntiDoS rule deleted successfully',
      DELETE_ERROR: 'Error while deleting AntiDoS rule',
      NAME: 'Name',
      ACTION: 'Action',
      STATUS: 'Status',
      PROTOCOL: 'Protocol',
      TYPE: 'Type',
      TYPE_ICMP_ERROR:
        'The Services field must be empty when selecting type ICMP flood in the AntiDoS rules',
      SOURCE: 'Source',
      DESTINATION: 'Destination',
      TRACK_BY: 'Track by',
      TRACK_BY_SOURCE: 'Source',
      TRACK_BY_DESTINATION: 'Destination',
      THRESHOLD: 'Threshold',
      THRESHOLD_INVALID: 'Threshold only allows values between {min} and {max}',
      INTERVAL_INVALID: 'Interval only allows values between {min} and {max}',
      ACTIONS: 'Actions',
      ANY: 'Any',
      MAX_NETWORKS_ERROR:
        'It has exceeded the maximum number of networks and ports allowed in an AntiDoS rule',
    },
    ACTIVATE_OTSIGNATURES: 'Activate OT signatures',
    EXCEPTIONS: 'Exceptions',
    CHANGE_PROTECTION_TYPE: 'Change protection type',
    CONFIRM_DELETE: 'Are you sure you want to delete this rule?',
    DELETE_SUCCESS: 'Rule deleted successfully',
    ADD_SUCCESS: 'Rule added successfully',
    ADD_ERROR: 'Error while adding rule',
    CONFIRM_EXCEPTION_DELETE: 'Are you sure you want to delete this exception?',
    DELETE_EXCEPTION_SUCCESS: 'Exception deleted successfully',
    ADD_EXCEPTION_SUCCESS: 'Exception added successfully',
    CHECK_SYNTAX: 'Check rule syntax',
    ADD_RULE: 'Add new rule',
    ADD_RULE_HELP: 'Enter the rules one at a time',
    EDIT_RULE: 'Edit rule',
    CHANGE_ACTION: 'Change Action',
    EDIT_SUCCESS: 'Rule modified successfully',
    EDIT_ERROR: 'Error while modifying the rule',
    DUPLICATED_DESCRIPTION: 'Rule description is already in use',
  },
  ERRORS: {
    IP_REGISTERED: 'IP already registered',
    HOSTNAME_INVALID: 'Hostname not valid',
    IP_NOT_RESOLVED: 'Could not resolve IP',
  },
  ANTIVIRUS: {
    TITLE_ANTIVIRUS: 'Antivirus',
    TITLE_ANTI_CNC: 'Anti Command & Control',
    TITLE_SANDBOX: 'Send file to Sandbox',
    TITLE_FILE_BLACKLIST: 'File Blacklist',
    MD5_HASHES_FILE_BLACKLIST: 'List of MD5 hashes',
    MD5_HASHES_FILE_BLACKLIST_ERROR: 'Invalid MD5 hashes',
    SUCCESS_UPDATE: 'Updated successfully',
    SUCCESS_ANTIVIRUS: 'Antivirus status has been modified successfully',
    SUCCESS_ANTI_CNC: 'Anti C&C status has been modified successfully',
    SUCCESS_FILE_BLACKLIST: 'File Blacklist has been modified successfully',
    SUCCESS_MD5_HASHES: 'MD5 hashes have been modified successfully',
    ERROR_INVALID_MD5_HASH: 'Invalid MD5 hash found',
    ERROR_FETCH: 'Error while loading Antivirus and Anti C&C options',
    ERROR_UPDATE: 'Error while modifying Antivirus and Anti C&C options',
  },
  TIME: {
    FROM: 'From',
    TO: 'To',
    AT: 'At',
    JANUARY: 'January',
    FEBRUARY: 'February',
    MARCH: 'March',
    APRIL: 'April',
    MAY: 'May',
    JUNE: 'June',
    JULY: 'July',
    AUGUST: 'August',
    SEPTEMBER: 'September',
    OCTOBER: 'October',
    NOVEMBER: 'November',
    DECEMBER: 'December',
    MONDAY: 'Monday',
    SHORT_MONDAY: 'M',
    MEDIUM_MONDAY: 'Mon',
    TUESDAY: 'Tuesday',
    MEDIUM_TUESDAY: 'Tue',
    SHORT_TUESDAY: 'T',
    WEDNESDAY: 'Wednesday',
    MEDIUM_WEDNESDAY: 'Wed',
    SHORT_WEDNESDAY: 'W',
    THURSDAY: 'Thursday',
    MEDIUM_THURSDAY: 'Thu',
    SHORT_THURSDAY: 'T',
    FRIDAY: 'Friday',
    MEDIUM_FRIDAY: 'Fri',
    SHORT_FRIDAY: 'F',
    SATURDAY: 'Saturday',
    MEDIUM_SATURDAY: 'Sat',
    SHORT_SATURDAY: 'S',
    SUNDAY: 'Sunday',
    MEDIUM_SUNDAY: 'Sun',
    SHORT_SUNDAY: 'S',
    NEXT_MONTH: 'Next Month',
    PREV_MONTH: 'Previous Month',
    OPEN_MONTH: 'Open Month Selector',
    OPEN_YEAR: 'Open Year Selector',
    CLOSE_MONTH: 'Close Month Selector',
    CLOSE_YEAR: 'Close Year Selector',
    DEFAULT_PLACEHOLDER: 'Select...',
    FIRST_DAY_OF_WEEK: 'Sunday',
    DAYS: 'Days',
    TIMEZONE: 'Timezone',
    DAILY: 'Daily',
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly',
    YEARLY: 'Yearly',
    DAYS_OF_WEEK: 'Days of the week',
    MONTHS: 'Months',
    WEEKS: 'Weeks',
    DAYS_OF_MONTH: 'Days of the month',
    LAST_DAY_OF_MONTH: 'Last day',
    HOUR: 'Hour',
    YEARS: 'Years',
  },
  LICENSES: {
    TYPE: 'Type',
    NUMBER: 'Number',
    VALIDITY: 'Validity',
    VALIDITY_INVALID: 'Validity not valid',
    ALLOCATION: 'Allocation',
    AVAILABLE: 'Available',
    AVAILABLE_USERS: 'Available users',
    AVAILABLE_DEVICES: 'Available devices',
    TOTAL: 'Total',
    CLOSE_TO_EXPIRY: 'About to expire',
    EXPIRED: 'Expired',
    USED: 'In use',
    TIME_CONSUMPTION: 'Time consumed',
    LICENSE_TYPE: 'License type',
    NUMBER_DEVICES: 'Number of devices',
    NUMBER_LICENSES: 'Number of licenses',
    CREATED: 'Successfully License created',
    ADD_LICENSE: 'Add license',
    ACTIVATE: 'Activate license',
    UPLOAD: 'Upload .txt file',
    EXPIRATION_DATE: 'Expiration date',
    ASSIGN_DATE: 'Assign date',
    NO_LICENSES_AVAILABLE: 'No licenses found',
    ADD: 'Add license',
    DEVICE_CLOUD_UUID: 'Cloud device',
    TRIAL_PERIOD: 'Trial period',
    EXPIRED_TOOLTIP: 'This license has expired',
    CLOSE_TO_EXPIRY_TOOLTIP:
      "This license will expire in a month's time or less",
    CONFIRM_ERROR: 'Error while removing expired license',
    CONFIRM_DELETE: 'Are you sure you want to delete the expired license?',
    SUCCESS_DELETE: 'Expired license removed successfully',
    ADMIN_CONFIRM_ERROR: 'Error while removing license',
    ADMIN_CONFIRM_DELETE: 'Are you sure you want to delete the license?',
    ADMIN_SUCCESS_DELETE: 'License removed successfully',
    SELECT_ALL_UTM: 'Select all UTM licenses',
    SELECT_ALL_CLOUD: 'Select all Cloud licenses',
    DELETE_ALL_EXPIRED_LICENSES: 'Delete all expired licenses',
    DELETE_ALL_CONFIRM_ERROR: 'Error while removing expired licenses',
    DELETE_ALL_CONFIRM_DELETE:
      'Are you sure you want to delete the expired licenses?',
    DELETE_ALL_SUCCESS_DELETE: 'Expired licenses removed successfully',
    LICENSE_DETECTED: 'License detected',
    LICENSE_NOT_FOUND: 'License not found',
    FILE_ERROR: 'The file has errors',
    LICENSE_INVALID: 'License not valid',
    LICENSE_EMPTY: 'License type cannot be empty',
    TENANT_INVALID: 'License tenant does not match',
    DATA_INVALID: 'License has invalid data',
    ALREADY_ACTIVATED: 'License already activated',
    SSE_USERS: 'SSE Users',
    ASSIGN_LICENSES: 'Assign licenses',
    ERROR_NO_LICENSES: 'No licenses selected',
    LICENSES_MANAGER: 'Licenses manager',
    LICENSES_ASSIGNED: 'Assigned licenses',
    IPSOT_WITH_IPS: 'IPS OT licenses require IPS licenses',
    NUMBER_INFO:
      'The number of licenses depends on the users in the case of SSE licenses and on the devices for the rest of the licenses',
    N_DEVICES: '{n} Devices',
    N_USERS: '{n} Users',
    SELECT_TENANT: 'Select a tenant',
    SUCCESS_ADD: 'License adding successfully',
    ERROR_ADD: 'Error while adding license',
    NUM_FIELD_REQUIRED: 'The number of licenses field is required',
    NUM_FIELD_INVALID: 'The number of licenses field is invalid',
  },
  RESELLER_LICENSES: {
    SUCCESS_ASSIGN: 'License assigned successfully',
    ERROR_ASSIGN: 'Error while assigning license',
    INVALID_RESELLER: 'The reseller is not valid for the license',
    UNSHAREABLE_ERROR: 'Number of licenses exceeded',
    USERS_NUMBER_ERROR: 'Users number must be a multiple of 10',
    CONFIRM_ERROR: 'Error while removing reseller license',
    CONFIRM_DELETE: 'Are you sure you want to delete the reseller license?',
    SUCCESS_DELETE: 'Reseller license removed successfully',
  },
  REPORTS: {
    FILTER: 'Filter',
    FROM: 'From',
    TO: 'To',
    FEATURES: 'Features',
    GENERAL: 'General',
    IPS: 'IPS',
    DLP: 'DLP',
    THROUGHPUT: 'Throughput',
    WEBFILTER: 'Web Filter',
    APPLICATIONS: 'Applications',
    INTERFACES: 'Interfaces',
    ANTIVIRUS: 'Antivirus',
    VIEW: 'View',
    HIDE: 'Hide',
    DOWNLOAD: 'Download',
    SCHEDULE: 'Schedule',
    SCHEDULE_NEW_REPORT: 'Schedule new report',
    SCHEDULED_REPORTS: 'Scheduled reports',
    TASK_NAME: 'Task name',
    TASK_NAME_PLACEHOLDER: 'Task name',
    PERIOD: 'Period',
    TIMEFRAME: 'Time frame',
    ALL: 'All',
    GENERATE: 'Preview',
    USER: 'Users',
    NETWORK: 'Networks',
    NETWORK_RANGE: 'Network Ranges',
    GEOLOCATION: 'Geolocations',
    FQDN: 'FQDN',
    MAC: 'MAC',
    TOP_SUBCATEGORY_ACTION: 'Top 10 Subcategories by Action',
    TOP_SUBCATEGORY_SRC_IP: 'Top 10 Source IPs by Subcategories',
    REPORTS_TEMPLATES: 'Report templates',
    TRAFFIC_BY_APPLICATION: 'Troughput by Application',
    REPORT_PREVIEW: 'Report preview',
  },
  REPORTS_TEMPLATES: {
    NAME: 'Name',
    FROM: 'From',
    TO: 'To',
    SOURCES: 'Source',
    DESTINATIONS: 'Destination',
    DESTINATION_PORT: 'Destination Port',
    APPLICATIONS: 'Applications',
    HOSTNAME: 'Hostname',
    CATEGORIES: 'Categories',
    FEATURES: 'Features',
    ADD: 'Add report template',
    EDIT: 'Edit report template',
    ADD_SUCCESS: 'Report template created successfully',
    ADD_ERROR: 'Error when creating report template',
    EDIT_SUCCESS: 'Report template updated successfully',
    EDIT_ERROR: 'Error when editing report template',
    DELETE_SUCCESS: 'Report template deleted successfully',
    DELETE_ERROR: 'Error when deleting report template',
    REQUIRED_FIELDS_ERROR: 'Please fill all required fields',
    REQUIRED_NAME_ERROR: 'Name Required',
    REQUIRED_FROM_ERROR: 'Start Date Required',
    REQUIRED_TO_ERROR: 'End Date Required',
    REQUIRED_FEATURES_ERROR: 'Select at least one feature',
    FROM_AFTER_TO_ERROR: 'Start date cannot be after end date.',
    ERROR_DUPLICATED_NAME: 'This name already exists',
  },
  EMAIL: {
    TO: 'To',
    CC: 'CC',
    BCC: 'BCC',
    SUBJECT: 'Subject',
    CONTENT: 'Content',
  },
  SCHEDULED_REPORTS: {
    CONFIRM_DELETE: 'Are you sure you want to delete the scheduled report?',
    CRON: 'Schedule',
    DISCARD: 'Discard',
    EDIT_SCHEDULED: 'Edit scheduled report',
    EMAILS: 'Emails',
    ERROR_DELETE: 'Error while deleting the scheduled report',
    ERROR_FETCH: 'Error while fetching the scheduled reports',
    LAST_EXEC: 'Last execution',
    NAME: 'Name',
    NEW_SCHEDULED: 'Schedule new report',
    NEXT_EXEC: 'Next execution',
    SAVE: 'Save changes',
    STATUS: 'Status',
    SUCCESS_DELETE: 'Scheduled report deleted successfully',
  },
  LICENSE_TYPES: {
    BS: 'Cloud',
    UTM_R: 'UTM for RS & TE-100 Series',
    UTM_M: 'UTM for M, Celer, Regesta, TE-200 & TE-220 Series',
    UTM_A: 'UTM for Atlas, H5 & TE-400 Series',
    UTM_V: 'UTM for VM Series',
    UTM_S: 'UTM for SDE Series',
    UTM_F: 'UTM for Ares & TE-600 Series',
    AS: 'Antispam',
    AV: 'Antivirus',
    AC: 'Application control',
    DLP: 'DLP / Content Filter',
    FW: 'Firewall',
    IPS: 'IDS/IPS',
    IPSOT: 'IDS/IPS OT',
    SSL: 'SSL Inspection',
    ZTNA: 'ZTNA',
    RA: 'Remote Access',
    UF: 'Web Filter',
    CB: 'CASB',
    DNS: 'DNS Filter',
    UID: 'User Identification',
    VPNTUN: 'VPN Tunnel',
    SSE: 'SSE User Based',
    USER_BASED: '{license} User Based',
    N_USERS: '{n} users',
    SSE_MULTIPLIER_ERROR:
      'The multiplier of a SSE license cannot exceed {maxMultiplier}',
  },
  COUNTRIES: {
    '-': 'Unknown',
    US: 'United States of America',
    CN: 'China',
    AU: 'Australia',
    JP: 'Japan',
    TH: 'Thailand',
    IN: 'India',
    MY: 'Malaysia',
    KR: 'Korea (Republic of)',
    SG: 'Singapore',
    HK: 'Hong Kong',
    TW: 'Taiwan (Province of China)',
    KH: 'Cambodia',
    PH: 'Philippines',
    VN: 'Viet Nam',
    NO: 'Norway',
    BR: 'Brazil',
    AR: 'Argentina',
    PS: 'Palestine, State of',
    ES: 'Spain',
    FR: 'France',
    NL: 'Netherlands',
    CZ: 'Czechia',
    DE: 'Germany',
    AT: 'Austria',
    CH: 'Switzerland',
    GB: 'United Kingdom of Great Britain and Northern Ireland',
    IT: 'Italy',
    GR: 'Greece',
    RU: 'Russian Federation',
    IE: 'Ireland',
    DK: 'Denmark',
    PT: 'Portugal',
    SE: 'Sweden',
    GH: 'Ghana',
    CM: 'Cameroon',
    BE: 'Belgium',
    ZA: 'South Africa',
    FI: 'Finland',
    TR: 'Turkey',
    AE: 'United Arab Emirates',
    HU: 'Hungary',
    PL: 'Poland',
    JO: 'Jordan',
    RO: 'Romania',
    UG: 'Uganda',
    AM: 'Armenia',
    TZ: 'Tanzania, United Republic of',
    BI: 'Burundi',
    UY: 'Uruguay',
    CL: 'Chile',
    LU: 'Luxembourg',
    PE: 'Peru',
    BG: 'Bulgaria',
    UA: 'Ukraine',
    EG: 'Egypt',
    CA: 'Canada',
    IL: 'Israel',
    QA: 'Qatar',
    MD: 'Moldova (Republic of)',
    SC: 'Seychelles',
    IQ: 'Iraq',
    LV: 'Latvia',
    UZ: 'Uzbekistan',
    SK: 'Slovakia',
    KZ: 'Kazakhstan',
    GE: 'Georgia',
    EE: 'Estonia',
    HR: 'Croatia',
    AL: 'Albania',
    LT: 'Lithuania',
    SA: 'Saudi Arabia',
    MT: 'Malta',
    CR: 'Costa Rica',
    CY: 'Cyprus',
    IR: 'Iran (Islamic Republic of)',
    ID: 'Indonesia',
    BH: 'Bahrain',
    MX: 'Mexico',
    CO: 'Colombia',
    SY: 'Syrian Arab Republic',
    LB: 'Lebanon',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
    OM: 'Oman',
    RS: 'Serbia',
    IS: 'Iceland',
    SI: 'Slovenia',
    MK: 'North Macedonia',
    LI: 'Liechtenstein',
    JE: 'Jersey',
    BA: 'Bosnia and Herzegovina',
    AZ: 'Azerbaijan',
    KG: 'Kyrgyzstan',
    IM: 'Isle of Man',
    GG: 'Guernsey',
    GI: 'Gibraltar',
    LY: 'Libya',
    YE: 'Yemen',
    BY: 'Belarus',
    RE: 'Reunion',
    MQ: 'Martinique',
    KW: 'Kuwait',
    LK: 'Sri Lanka',
    SZ: 'Eswatini',
    CD: 'Congo (Democratic Republic of the)',
    GP: 'Guadeloupe',
    BD: 'Bangladesh',
    BT: 'Bhutan',
    BN: 'Brunei Darussalam',
    PM: 'Saint Pierre and Miquelon',
    PA: 'Panama',
    LA: "Lao People's Democratic Republic",
    GU: 'Guam',
    MP: 'Northern Mariana Islands',
    DO: 'Dominican Republic',
    NG: 'Nigeria',
    NZ: 'New Zealand',
    EC: 'Ecuador',
    VE: 'Venezuela (Bolivarian Republic of)',
    PR: 'Puerto Rico',
    VI: 'Virgin Islands (U.S.)',
    PK: 'Pakistan',
    MN: 'Mongolia',
    PG: 'Papua New Guinea',
    TL: 'Timor-Leste',
    SB: 'Solomon Islands',
    VU: 'Vanuatu',
    FJ: 'Fiji',
    CK: 'Cook Islands',
    TO: 'Tonga',
    NP: 'Nepal',
    KE: 'Kenya',
    MO: 'Macao',
    NI: 'Nicaragua',
    JM: 'Jamaica',
    TT: 'Trinidad and Tobago',
    LS: 'Lesotho',
    AF: 'Afghanistan',
    MA: 'Morocco',
    GD: 'Grenada',
    VG: 'Virgin Islands (British)',
    KN: 'Saint Kitts and Nevis',
    AG: 'Antigua and Barbuda',
    VC: 'Saint Vincent and The Grenadines',
    BS: 'Bahamas',
    DM: 'Dominica',
    KY: 'Cayman Islands',
    LC: 'Saint Lucia',
    MM: 'Myanmar',
    BB: 'Barbados',
    PY: 'Paraguay',
    GT: 'Guatemala',
    UM: 'United States Minor Outlying Islands',
    TM: 'Turkmenistan',
    TK: 'Tokelau',
    MV: 'Maldives',
    NC: 'New Caledonia',
    WF: 'Wallis and Futuna',
    SM: 'San Marino',
    ME: 'Montenegro',
    SV: 'El Salvador',
    AD: 'Andorra',
    MC: 'Monaco',
    BO: 'Bolivia (Plurinational State of)',
    GL: 'Greenland',
    TJ: 'Tajikistan',
    FO: 'Faroe Islands',
    MF: 'Saint Martin (French Part)',
    LR: 'Liberia',
    MU: 'Mauritius',
    BW: 'Botswana',
    MZ: 'Mozambique',
    TN: 'Tunisia',
    MG: 'Madagascar',
    AO: 'Angola',
    NA: 'Namibia',
    CI: "Cote D'ivoire",
    SD: 'Sudan',
    MW: 'Malawi',
    GA: 'Gabon',
    ML: 'Mali',
    BJ: 'Benin',
    CV: 'Cabo Verde',
    RW: 'Rwanda',
    CG: 'Congo',
    GM: 'Gambia',
    GN: 'Guinea',
    BF: 'Burkina Faso',
    SO: 'Somalia',
    SL: 'Sierra Leone',
    NE: 'Niger',
    CF: 'Central African Republic',
    TG: 'Togo',
    SS: 'South Sudan',
    GQ: 'Equatorial Guinea',
    SN: 'Senegal',
    DZ: 'Algeria',
    AS: 'American Samoa',
    MR: 'Mauritania',
    DJ: 'Djibouti',
    KM: 'Comoros',
    IO: 'British Indian Ocean Territory',
    TD: 'Chad',
    YT: 'Mayotte',
    NR: 'Nauru',
    WS: 'Samoa',
    FM: 'Micronesia (Federated States of)',
    PF: 'French Polynesia',
    HN: 'Honduras',
    BM: 'Bermuda',
    BZ: 'Belize',
    CW: 'Curacao',
    AX: 'Aland Islands',
    GF: 'French Guiana',
    NU: 'Niue',
    TV: 'Tuvalu',
    PW: 'Palau',
    MH: 'Marshall Islands',
    KI: 'Kiribati',
    KP: "Korea (Democratic People's Republic of)",
    AW: 'Aruba',
    CU: 'Cuba',
    HT: 'Haiti',
    SR: 'Suriname',
    GY: 'Guyana',
    VA: 'Holy See',
    ST: 'Sao Tome and Principe',
    ET: 'Ethiopia',
    ER: 'Eritrea',
    GW: 'Guinea-Bissau',
    FK: 'Falkland Islands (Malvinas)',
    AI: 'Anguilla',
    TC: 'Turks and Caicos Islands',
    SX: 'Sint Maarten (Dutch Part)',
    BV: 'Bouvet Island',
    SJ: 'Svalbard and Jan Mayen',
    BL: 'Saint Barthelemy',
    NF: 'Norfolk Island',
    MS: 'Montserrat',
    BQ: 'Bonaire, Sint Eustatius and Saba',
    GS: 'South Georgia and The South Sandwich Islands',
    PN: 'Pitcairn Islands',
    SH: 'Saint Helena, Ascension and Tristan da Cunha',
  },
  DHCP_CONFIGS: {
    MAXIMUM_REACHED: 'Only 5 DHCP ranges are allowed',
    SUCCESS_CREATE: 'DHCP configuration created successfully',
    ERROR_CREATE: 'Error creating DHCP configuration',
    SUCCESS_UPDATE: 'DHCP configuration updated successfully',
    ERROR_UPDATE: 'Error updating DHCP configuration',
    SUCCESS_DELETE: 'DHCP configuration deleted successfully',
    ERROR_DELETE: 'Error deleting DHCP configuration',
    DUPLICATED_NAME: 'Name is already in use',
    MISSING_NAME: 'Name is missing',
    MISSING_SUBNET: 'Subnet is missing',
    MISSING_START_IP: 'Start IP is missing',
    MISSING_END_IP: 'End IP is missing',
    SUBNET_ERROR: 'Subnet is invalid',
    SUBNET_IN_RANGE: 'IP Address must not be within the given address range',
    START_IP_ERROR: 'Start IP of the address range is invalid',
    START_IP_NOT_IN_SUBNET_ERROR:
      'Start IP of the address range is not within the given subnet',
    START_IP_WRONG_VALUE:
      'Start IP of the address range cannot be the start of the given subnet',
    END_IP_ERROR: 'End IP of the address range is invalid',
    END_IP_NOT_IN_SUBNET_ERROR:
      'End IP of the address range is not within the given subnet',
    INCORRECT_IP_RANGE: 'Address range has to be ascendent',
    EQUAL_IP_RANGE:
      'Start IP and End IP of the address range have to be different',
    ROUTER_ERROR: 'Router DNS is invalid',
    PRIMARY_DNS_ERROR: 'Primary DNS is invalid',
    SECONDARY_DNS_ERROR: 'Secondary DNS is invalid',
    INVALID_RANGES: 'Invalid one or more ranges',
    REQUIRED_START_IP: 'Required start IP',
    REQUIRED_END_IP: 'Required end IP',
    INVALID_START_IP: 'Invalid start IP',
    INVALID_END_IP: 'Invalid end IP',
    INVALID_INTERFACE: 'Invalid Interface',
    DEFAULT_ROUTER: {
      BAD_IPV4_BLOCK: 'Default Router is invalid',
      NOT_IN_SUBNET: 'Default Router is not within the given subnet',
      IP_ADDRESS_IN_RANGE:
        'Default Router must not be within the given address range',
      START_IP_WRONG_VALUE:
        'Default router cannot be the start of the given subnet',
    },
  },
  DHCP_CONFIG_RANGES: {
    MAXIMUM_REACHED: 'Only 5 DHCP ranges are allowed',
    START_IP: {
      IP_TAKEN: 'Start IP already taken in other range',
      BLANK: 'Start IP could not be empty',
    },
    END_IP: {
      IP_TAKEN: 'End IP already taken in other range',
      BLANK: 'End IP could not be empty',
    },
  },
  FIREWALL: {
    TITLE: 'Firewall protection',
    ADD_RULE_TITLE: 'Add new Firewall rule',
    ADD_SUCCESS: 'Firewall rule added successfully',
    ADD_ERROR: 'Error while adding Firewall rule',
    EDIT_RULE_TITLE: 'Edit Firewall rule',
    EDIT_SUCCESS: 'Firewall rule updated successfully',
    EDIT_ERROR: 'Error while updating Firewall rule',
    NAME: 'Name',
    NAME_PLACEHOLDER: 'Rule name...',
    SOURCE: 'Source',
    SOURCE_PLACEHOLDER: '2.2.2.2/24:24',
    SOURCE_ERROR: 'At least one of the sources is invalid',
    DESTINATION: 'Destination',
    DESTINATION_PLACEHOLDER: '2.2.2.2/24:24',
    DESTINATION_ERROR: 'At least one of the destinations is invalid',
    CONFIRM_DELETE: 'Are you sure you want to delete the Firewall rule?',
    DELETE_SUCCESS: 'Firewall rule deleted successfully',
    DELETE_ERROR: 'Error while deleting Firewall rule',
    ACTION: 'Action',
    PASS: 'Pass',
    DROP: 'Drop',
    STATUS: 'Status',
    ORDER: 'Order',
    ID: 'ID',
    ACTIONS: 'Options',
    ADD_RULE_ABOVE: 'Add rule above',
    ADD_RULE_BELOW: 'Add rule below',
    ADD_RULE_TOP: 'Add rule to top',
    ADD_RULE_BOTTOM: 'Add rule bottom',
    CONFIRM_DELETE_NETWORK: 'Are you sure you want to delete the network?',
    CONFIRM_DELETE_NETWORKS_GROUP:
      'Are you sure you want to delete the networks group?',
    SERVICES: 'Services',
    ANY: 'Any',
    LOCAL: 'LOCAL',
    PROTOCOL: 'Protocol',
    INTERFACE: 'Interfaces',
    INCOMING_INTERFACES: 'Incoming interfaces',
    PROFILES: 'Profiles',
    SHOW_SYSTEM_RULES: 'Show system rules',
    SYSTEM_RULES_TITLE: 'Implied rules',
    USER_RULES_TITLE: 'User rules',
    INVALID_NAME: 'Invalid name',
    INVALID_DESCRIPTION: 'Invalid description',
    INVALID_DESCRIPTION_LENGTH: 'Invalid description length',
    INVALID_NAME_LENGTH: 'Name must be between 2 and 58 characters',
    INVALID_ORDER: 'Invalid order',
    CHANGE_ORDER: 'Change order',
    ENABLE_SCHEDULE: 'Rule enabled by scheduler.',
    DISABLE_SCHEDULE: 'Rule disabled by scheduler.',
    INVALID_SCHEDULE_LENTGH: 'The rule can only have one schedule',
    DEVICES_UNSYNCHRONIZED: 'Devices unsynchronized: {devices}',
    SSL_PROFILE_INCOMPATIBLE_INTERFACES:
      'SSL Profile cannot be used with bridge interfaces',
    SSL_PROFILE_MISSING_INTERFACES:
      'SSL Profile must be used with incoming interfaces',
    SSL_PROFILE_PROTOCOL_ERROR:
      'SSL Inspection can only be used with HTTPS, POP3S and SMTPS',
  },
  NAT: {
    TITLE: 'NAT rules',
    ADD_RULE_TITLE: 'Add NAT rule',
    EDIT_RULE_TITLE: 'Edit NAT rule',
    NAME: 'Name',
    NAME_PLACEHOLDER: 'NAT rule name...',
    SOURCE: 'Source NAT (SNAT)',
    DESTINATION: 'Destination NAT (DNAT)',
    TYPE: 'NAT Type',
    SERVICES: 'DNAT redirected port',
    STATIC: 'Static',
    DYNAMIC: 'Dynamic',
    AUTO: 'Masquerade',
    AUTO_DESCRIPTION: 'Use automatically the outbound interface IP address',
    SERVICES_TYPE_ERROR: 'All service types must be the same',
    NAT_SERVICES_TYPE_ERROR:
      'Service types must be the same as NAT service type',
    SERVICES_PORT_ERROR: 'Service and NAT service must have one port each one',
    NAT_FIELD_REQUIRED: 'Source NAT or destination NAT must be filled',
    MAX_SOURCE_NAT: 'Max NAT source elements is 1',
    MAX_DESTINATION_NAT: 'Max NAT destination elements is 1',
    MAX_SERVICE_NAT: 'Max NAT services elements is 1',
    SERVICE_NAT_GROUPS_ERROR: 'NAT services cannot have groups',
    INTERNAL_INTERFACE: 'Outbound interface',
    EXTERNAL_INTERFACE: 'Inbound interface',
    MAX_INTERNAL_INTERFACE: 'Max outbound interfaces is 1',
    MAX_EXTERNAL_INTERFACE: 'Max inbound interfaces is 1',
    UNDELETED_ENTITY: 'The rule cannot be deleted',
    UNPROCESSED_ENTITY: 'The rule cannot be processed',
  },
  OBJECT_EXPLORER: {
    NETWORK_ELEMENTS: 'Network elements',
    NETWORKS: 'Networks',
    NETWORK: 'Network',
    NETWORK_IPV4: 'IPv4 Network',
    NETWORK_IPV6: 'IPv6 Network',
    NETWORK_GROUPS: 'Network Groups',
    NETWORK_GROUP: 'Network Group',
    NETWORK_GROUP_ADD: 'Add new Network Group',
    NETWORK_GROUP_EDIT: 'Edit Network Group',
    SERVICES: 'Services',
    FQDN: 'FQDN',
    FQDN_PLACEHOLDER: 'FQDN...',
    FQDN_ADD: 'Add new FQDN',
    FQDN_GROUP: 'FQDN Group',
    FQDN_GROUP_ADD: 'Add new FQDN Group',
    FQDN_GROUP_EDIT: 'Edit FQDN Group',
    GEOLOCATION: 'Geolocation',
    GEOLOCATION_GROUP: 'Geolocation Group',
    GEOLOCATION_GROUP_ADD: 'Add new Geolocation Group',
    GEOLOCATION_GROUP_EDIT: 'Edit Geolocation Group',
    IP: 'IP Address',
    IPV4: 'IPV4 Address',
    IPV4_ERROR: 'IPV4 Address is not valid',
    IP_RANGE: 'IP Address Range',
    NETWORK_IPV4_RANGE: 'IPv4 Range',
    NETWORK_IPV6_RANGE: 'IPv6 Range',
    MAC: 'MAC Addresses',
    MAC_GROUP_ADD: 'Add new MAC Addresses Group',
    MAC_GROUP_EDIT: 'Edit MAC Addresses Group',
    USERS: 'Users',
    USERS_GROUP: 'Users Group',
    USER_GROUP_ADD: 'Add new User Group',
    USER_GROUP_EDIT: 'Edit User Group',
    NETWORK_RANGE: 'IP Range',
    NETWORK_RANGE_GROUP_ADD: 'Add new IP Range Group',
    NETWORK_RANGE_GROUP_EDIT: 'Edit IP Range Group',
    GROUPS: 'Groups',
    SCHEDULES: 'Schedules',
    SCHEDULE: 'Schedule',
    ALL_DAY: 'All day',
    START_TIME: 'Start time',
    STOP_TIME: 'Stop time',
    WEEKDAYS_ERROR: 'You must select at least one day of the week',
    TIME_ERROR: 'You must select a stop hour later than the start hour',
    ALREADY_IN_USE:
      "The object cannot be deleted because it is being used in other places. Please, check the 'Where used' list.",
    ALREADY_IN_USE_GROUP:
      "The object cannot be deleted because it is being used in other places. Please, check the 'Groups' list.",
    ALREADY_IN_USE_DNS:
      'The object cannot be deleted because it is being used in a DNS configuration.',
    OBJECT_LIST: 'List of objects',
    EDIT_NOT_ALLOWED: 'You are not allowed to modify preloaded objects',
    DELETE_NOT_ALLOWED: 'You are not allowed to delete preloaded objects',
    TCP_SERVICE_GROUP_ADD: 'Add new TCP Service Group',
    TCP_SERVICE_GROUP_EDIT: 'Edit TCP Service Group',
    UDP_SERVICE_GROUP_ADD: 'Add new UDP Service Group',
    UDP_SERVICE_GROUP_EDIT: 'Edit UDP Service Group',
    ICMP_SERVICE_GROUP_ADD: 'Add new ICMP Service Group',
    ICMP_SERVICE_GROUP_EDIT: 'Edit ICMP Service Group',
    OTHER_SERVICE_GROUP_ADD: 'Add new Other Service Group',
    OTHER_SERVICE_GROUP_EDIT: 'Edit Other Service Group',
    GROUP_DETACH_SUCCESS: 'Object detached from the group successfully',
    GROUP_EMPTY_ERROR: 'The group cannot be empty',
    DELETE_SUCCESS: 'Object deleted successfully',
    TCP_SERVICE: 'TCP Service',
    UDP_SERVICE: 'UDP Service',
    ICMP_SERVICE: 'ICMP Service',
    OTHER_SERVICE: 'Other Service',
    TCP_SERVICE_GROUP: 'TCP Service Group',
    UDP_SERVICE_GROUP: 'UDP Service Group',
    ICMP_SERVICE_GROUP: 'ICMP Service Group',
    OTHER_SERVICE_GROUP: 'Other Service Group',
    CASB_TENANTS: 'CASB Tenants',
    DLP: 'DLP',
    FILE_TYPES: 'File types',
    FILE_NAMES: 'File names',
    CONTENTS: 'Content',
    DLP_FILE_NAME_GROUP_ADD: 'Add DLP File Name Group',
    DLP_FILE_TYPE_GROUP_ADD: 'Add DLP File Type Group',
    DLP_FILE_NAME_GROUP_EDIT: 'Edit DLP File Name Group',
    DLP_FILE_TYPE_GROUP_EDIT: 'Edit DLP File Type Group',
    DNS_RECORD: 'DNS Record',
    DNS_RECORDS: 'DNS Records',
    IP_ADDRESS_MODERATE: 'IP Address (moderate mode)',
    IP_ADDRESS_STRICT: 'IP Address (strict mode)',
    SSL_PROFILE: 'SSL VPN',
    IPSEC_PROFILE: 'IPSEC VPN',
    APPLICATION_STATUS: 'Application Status',
    BUILD: 'Windows Build',
    USER_BUILD: 'User Build',
    RUNNING: 'Running',
    INACTIVE: 'Offline',
    NO_SELECTED_DAYS: 'No selected days',
  },
  CASB_TENANT_OBJECTS: {
    TARGET: 'CASB Domain',
    ADD: 'Add CASB Tenant',
    EDIT: 'Edit CASB Tenant',
    EDIT_SUCCESS: 'Successfully updated CASB Tenant',
    ADD_SUCCESS: 'Successfully added CASB Tenant',
  },
  NETWORK_OBJECTS: {
    CASB_ID: 'CASB ID',
    CASB_ID_PLACEHOLDER: 'CASB ID...',
    IP: 'IP',
    IPV4: 'IPv4',
    IPV4_RANGE: 'IPv4 Range',
    IPV6: 'IPv6',
    IPV6_RANGE: 'IPv6 Range',
    NET_MASK: 'Net Mask',
    NET_MASK_IPV4: 'IPv4 Net Mask',
    NET_MASK_IPV6: 'IPv6 Net Mask',
    GROUPS: 'Groups',
    PROTOCOL: 'Protocol',
    PORT: 'Port',
    DETACH: 'Detach',
    REMOVE_NETWORK_FROM_GROUP: 'Remove network from this group',
    GROUP_ADD_SUCCESS: 'Network group created successfully',
    GROUP_ADD_ERROR: 'Error while creating the Network group',
    GROUP_EDIT_SUCCESS: 'Network group updated successfully',
    GROUP_EDIT_ERROR: 'Error while updating Network',
    GROUP_DELETE_SUCCESS: 'Network Group deleted successfully',
    GROUP_UPDATE_SUCCESS: 'Network Group updated successfully',
    GROUP_ALREADY_IN_USE:
      "The Network Group cannot be deleted because it is being used in other places. Please, check the 'Where used' list.",
    GROUP_IPV4_ERROR: 'At least one of the IPv4 addresses is not valid',
    GROUP_IPV6_ERROR: 'At least one of the IPv6 addresses is not valid',
    INVALID_IP_MASK: 'Invalid IP Mask',
    TYPE: 'Type',
    MAC_ADDRESS: 'MAC Address',
    MAC_ADDRESS_ADD: 'Add new MAC Address',
    MAC_ADDRESS_GROUP: 'MAC Address Group',
    DOMAIN: 'Domain',
    DOMAIN_PLACEHOLDER: 'Domain...',
    COMPUTER: 'Computer',
    COMPUTER_PLACEHOLDER: 'Computer...',
    GROUP: 'Group',
    GROUP_PLACEHOLDER: 'Group...',
    LOAD_ERROR: 'Error while loading Network objects',
    ALREADY_IN_USE:
      "The Network object cannot be deleted because it is being used in other places. Please, check the 'Where used' list.",
    DELETE_SUCCESS: 'Network object deleted successfully',
    ADD: 'Add new Network',
    ADD_SUCCESS: 'Network object created successfully',
    ADD_ERROR: 'Error while creating the Network object',
    EDIT: 'Edit Network',
    EDIT_SUCCESS: 'Network object updated successfully',
    EDIT_ERROR: 'Error while updating Network object',
    RANGE: 'Network Range',
    RANGE_GROUP: 'Network Range Group',
    SOURCE_SELECT: 'Select Source',
    SOURCES_SELECT: 'Select Source',
    DESTINATION_SELECT: 'Select Destination',
    SERVICE_SELECT: 'Select Service',
    RECORD_SELECT: 'Select Records',
    FQDN_SELECT: 'Select FQDN',
    NETWORK_SELECT: 'Select Network',
    NETWORK_RANGE_SELECT: 'Select Network Range',
    GEOLOCATION_SELECT: 'Select Geolocation',
    TCP_SERVICE_SELECT: 'Select TCP Service',
    UDP_SERVICE_SELECT: 'Select UDP Service',
    ICMP_SERVICE_SELECT: 'Select ICMP Service',
    OTHER_SERVICE_SELECT: 'Select other Service',
    MAC_SELECT: 'Select MAC',
    USER_SELECT: 'Select User',
    DLP_FILE_TYPE_SELECT: 'Select DLP File Type',
    DLP_FILE_NAME_SELECT: 'Select DLP File Name',
    DLP_CONTENT_GROUP_ADD: 'Add DLP Content',
    DLP_CONTENT_SELECT: 'Select DLP Content',
    FIELD_REQUIRED: 'At least one of the IPv4 or IPv6 fields must be filled',
    IPV4_ERROR:
      'The IPv4 field is not valid. Please, check the IP address and the network mask.',
    IPV6_ERROR:
      'The IPv6 field is not valid. Please, check the IP address and the network mask.',
    FIREWALL_IPV4_ERROR:
      "The IPv4 field cannot be empty because it is being used in a firewall rule with IPv4 Protocol. Please, check the 'Where used' list.",
    FIREWALL_IPV6_ERROR:
      "The IPv6 field cannot be empty because it is being used in a firewall rule with IPv6 Protocol. Please, check the 'Where used' list.",
    IPS: 'IPs list',
    CASB_TENANT_PLACEHOLDER: 'CASB Domain...',
    SOURCE: 'Source',
    AD_SERVERS: 'Azure Configs',
    EMAIL: 'Email',
    DUPLICATE_EMAIL: 'Duplicate Email',
    CREATE_BUILD: 'Build windows installer',
    DOWNLOAD_BUILD: 'Download Windows Client',
    SEND_EMAIL: 'Send Via Email',
    API_KEY: 'API Key',
    REGENERATE_API_KEY: 'Regenerate API Key',
    CONFIRM_API_REGENERATE: 'Are you sure you want to regenerate the API Key',
    API_KEY_REGENERATE_SUCCESS: 'API Key regenerated successfully',
    SYNC_AZURE_AD_USERS: 'Azure AD Users List',
    SYNCED_USERS: 'Synced Users',
    SYNCED_SUCCESS: 'Synced successfully',
    SYNC_FAILED: 'Synced failed',
    SSL_VPN: 'SSL VPN',
    CONNECTOR_SELECT: 'Select Connector',
    APPLICATION_SELECT: 'Select Application',
  },
  NETWORK_RANGE_OBJECTS: {
    ALREADY_IN_USE:
      "The IP Range object cannot be deleted because it is being used in other places. Please, check the 'Where used' list.",
    DELETE_SUCCESS: 'IP Range object deleted successfully',
    ADD: 'Add new IP Range',
    ADD_SUCCESS: 'IP Range object created successfully',
    ADD_ERROR: 'Error while creating the IP Range object',
    EDIT: 'Edit IP Range',
    EDIT_SUCCESS: 'IP Range object updated successfully',
    EDIT_ERROR: 'Error while updating IP Range object',
    FIELD_REQUIRED:
      'At least one of the IPv4 or IPv6 range fields must be filled',
    IPV4_FORMAT_ERROR:
      'The IPv4 field should have a range format like X.X.X.X - X.X.X.X',
    IPV6_FORMAT_ERROR:
      'The IPv6 field should have a range format like ::X - ::X',
    IPV4_ERROR:
      'At least one of the IPv4 addresses is not valid. Please, check the IP addresses. Format: X.X.X.X - X.X.X.X.',
    IPV6_ERROR:
      'At least one of the IPv6 addresses is not valid. Please, check the IP addresses. Format: ::X - ::X.',
  },
  FQDN_OBJECTS: {
    ADD_SUCCESS: 'FQDN object created successfully',
    ADD_ERROR: 'Error while creating the FQDN object',
  },
  MAC_OBJECTS: {
    ADD_SUCCESS: 'MAC address object created successfully',
    ADD_ERROR: 'Error while creating the MAC address object',
  },
  USER_OBJECTS: {
    ADD: 'Add new User',
    ADD_SUCCESS: 'The user object has been successfully created or updated',
    ADD_ERROR: 'Error while creating the User object',
    EDIT: 'Edit User',
    EDIT_SUCCESS: 'User object updated successfully',
    EDIT_ERROR: 'Error while updating User object',
    NO_DATA: 'No data available',
    NO_AZURE_CONFIG: 'No configuration selected',
  },
  SERVICES: {
    ALREADY_IN_USE:
      "Service object cannot be deleted because it is being used in other places. Please, check the 'Where used' list.",
    ADD_SUCCESS: 'Service object created successfully',
    ADD_ERROR: 'Error while creating the Service object',
    EDIT_SUCCESS: 'Service object updated successfully',
    EDIT_ERROR: 'Error while updating Service object',
    DELETE_SUCCESS: 'Service object deleted successfully',
    TCP_SERVICE: 'TCP Service',
    UDP_SERVICE: 'UDP Service',
    ICMP_SERVICE: 'ICMP Service',
    OTHER_SERVICE: 'Other services',
    ADD: 'Add new Service',
    EDIT: 'Edit Service',
    SELECT: 'Select Services',
    PORTS: 'Ports',
    PORTS_PLACEHOLDER: '80,443,7000-7009,514:512-1023',
    ICMP_TYPE: 'ICMP Type',
    ICMP_TYPE_PLACEHOLDER: 'ICMP Type...',
    ICMP_CODE: 'ICMP Code',
    ICMP_CODE_PLACEHOLDER: 'ICMP Code...',
    PROTOCOL_NUMBER: 'Protocol Number',
    PROTOCOL_NUMBER_PLACEHOLDER: 'Protocol Number...',
    LOAD_ERROR: 'Error while loading Service objects',
    PORTS_ERROR: 'At least one of the ports is invalid',
    ICMP_TYPE_ERROR: 'ICMP Type is invalid',
    ICMP_CODE_ERROR: 'ICMP Code is invalid',
    PROTOCOL_NUMBER_ERROR: 'Protocol Number is invalid',
    ICMP_GROUP_ALL_ERROR:
      'You cannot select other ICMP services when ALL_ICMP or ALL_ICMP6 is already selected',
    ICMP_VERSIONS_ERROR: 'You cannot mix ICMP and ICMP6 services',
  },
  SCHEDULE_OBJECTS: {
    LOAD_ERROR: 'Error while loading Schedule objects',
    ALREADY_IN_USE:
      "The object cannot be deleted because it is being used in other places. Please, check the 'Where used' list.",
    SCHEDULE_OBJECT_DELETED_SUCCESS: 'Schedule object deleted successfully',
    ADD: 'Add new Schedule',
    EDIT: 'Edit Schedule',
    SELECT: 'Select Schedule',
  },
  DNS_RECORD_OBJECTS: {
    LOAD_ERROR: 'Error while loading DNS Record objects',
    ALREADY_IN_USE:
      "The object cannot be deleted because it is being used in other places. Please, check the 'Where used' list.",
    DNS_RECORD_OBJECT_DELETED_SUCCESS: 'DNS Record object deleted successfully',
    ADD: 'Add new DNS Record',
    EDIT: 'Edit DNS Record',
    SELECT: 'Select DNS Record',
    CONFIRM_DELETE: 'Are you sure you want to delete this DNS Record?',
    DESELECT_NOT_ALLOWED: 'Initial DNS records cannot be deselected',
    ERROR_DUPLICATED:
      'The DNS record object cannot have the same name and IP address as another existing DNS record',
  },
  STATIC_ROUTES: {
    ADD: 'Add new Static Route',
    CONFIRM_DELETE: 'Do you want to delete the static route?',
    DISTANCE: 'Distance',
    DISTANCE_RANGE: 'Distance must be between 1 and 255',
    DUPLICATE_NAME: 'The name cannot be duplicated',
    EDIT: 'Edit Static Route',
    ERROR_CREATE: 'Error creating static route',
    ERROR_DELETE: 'Error deleting static route',
    ERROR_UPDATE: 'Error while updating static route',
    ERROR_FETCH: 'Error while fetching the static routes',
    ERROR_GATEWAY_INTERFACE: 'Must have a gateway or an interface',
    GATEWAY: 'Gateway',
    NAME: 'Name',
    INTERFACE: 'Interface',
    NETWORK: 'Network',
    REQUIRE_SUBNET: 'Subnet is required',
    SUCCESS_CREATE: 'Successfully created static route',
    SUCCESS_DELETE: 'Successfully deleted static route',
    SUCCESS_UPDATE: 'Successfully updated static route',
  },
  OBJECT_MODAL: {
    ADD: 'Add',
    CANCEL: 'Cancel',
    EDIT: 'Edit',
  },
  CASB: {
    MICROSOFT365: 'Microsoft 365',
    SALESFORCE: 'Salesforce',
    GOOGLE_DOCUMENTS: 'Google Documents',
    BOX: 'BOX',
    ACTION: 'Status',
    RULE_NAME: 'Rule Name',
    SOURCE: 'Users',
    APPLICATION: 'Application',
    TRUST: 'Trust',
    CONTENT: 'DLP Profile',
    ACTIVITY: 'Activity',
    CASB_TENANT: 'CASB Tenant',
    EDIT_RULE_TITLE: 'Edit CASB rule',
    ADD_RULE_TITLE: 'Add CASB rule',
  },
  XDR: {
    TITLE: 'XDR Policies',
    ADD: 'Add XDR policy',
    CONFIRM_DELETE: 'Are you sure you want to delete this rule?',
    DELETE_SUCCESS: 'Rule deleted successfully',
    ADD_SUCCESS: 'Rule added successfully',
    COMPROMISED: 'Compromised device',
    CPU: 'High CPU usage',
    MEMORY: 'High memory usage',
    ISOLATE: 'Isolate device',
    EMAIL: 'Send email to',
    SDWAN: 'Isolate device in SDWAN',
    REDIRECT: 'Redirect traffic',
    NAME: 'Name',
    EVENT: 'Event',
    ACTION: 'Action',
    NAME_PLACEHOLDER: 'New XDR policy',
    TRIGGER: 'XDR Triggers',
    POLICY_NAME: 'Policy Name',
    USER_NAME: 'Username',
    ACKNOWLEDGE: 'Acknowledge',
  },
  INACTIVITY_CONTROL: {
    INACTIVE_SESSION: 'Inactive session',
    INACTIVITY_WARNING:
      'The session will automatically expire in {s} seconds due to lack of activity.',
    STAY_LOGGED_IN: 'Stay logged in',
  },
  WEB_FILTER: {
    BASIC: 'Basic',
    MEDIUM: 'Medium',
    HIGH: 'High',
    CUSTOM: 'Custom',
    ADS: 'Advertisements',
    PARKED_DOMAINS: 'Parked domains',
    WEB_ADVERTISEMENTS: 'Web advertisements',
    DANGEROUS: 'Dangerous',
    GAMBLING: 'Gambling',
    HACKING: 'Hacking',
    OPEN_HTTP_PROXIES: 'Open HTTP Proxies',
    PAY_TO_SURF: 'Pay to surf',
    PEER_TO_PEER: 'Peer to Peer',
    FREE_TIME: 'Free time',
    COMPUTER_AND_INTERNET_INFO: 'Computer and Internet info',
    ENTERTAINMENT_AND_ARTS: 'Entertainment and Arts',
    FASHION_AND_BEAUTY: 'Fashion and Beauty',
    FOOD_AND_DINING: 'Food and Dining',
    HOME_AND_GARDEN: 'Home and Garden',
    HUNTING_AND_FISHING: 'Hunting and Fishing',
    INTERNET_PORTALS: 'Internet portals',
    KIDS: 'Kids',
    LOCAL_INFORMATION: 'Local information',
    MOTOR_VEHICLES: 'Motor vehicles',
    ONLINE_GREETING_CARDS: 'Online greeting cards',
    PERSONAL_SITES_AND_BLOGS: 'Personal sites and Blogs',
    PHILOSOPHY_AND_POLITICAL_ADVOCACY: 'Philosophy and Political advocacy',
    QUESTIONABLE: 'Questionable',
    REAL_ESTATE: 'Real estate',
    RECREATION_AND_HOBBIES: 'Recreation and Hobbies',
    RELIGION: 'Religion',
    SHOPPING: 'Shopping',
    SOCIETY: 'Society',
    SPORTS: 'Sports',
    TRAVEL: 'Travel',
    MALWARE_THREATS: 'Malware Threats',
    BOT_NETS: 'Bot Nets',
    CONFIRMED_SPAM_SOURCES: 'Confirmed spam sources',
    KEYLOGGERS_AND_MONITORING: 'Keyloggers and Monitoring',
    MALWARE_SITES: 'Malware sites',
    PHISHING_AND_OTHER_FRAUDS: 'Phising and other frauds',
    PROXY_AVOIDANCE_AND_ANONYMIZERS: 'Proxy avoidance and anonymizers',
    SPAM_URLS: 'Spam URLs',
    SPYWARE_AND_ADWARE: 'Spyware and Adware',
    UNCONFIRMED_SPAM_SOURCES: 'Unconfirmed spam sources',
    MEDIA: 'Media',
    MUSIC: 'Music',
    NEWS_AND_MEDIA: 'News and Media',
    SOCIAL_NETWORKING: 'Social networking',
    STREAMING_MEDIA: 'Streaming media',
    OTHERS: 'Others',
    UNCATEGORIZED: 'Uncategorized',
    UNRANKED: 'Unranked',
    UNRANKED_TRAFFIC: 'Unranked Traffic',
    SEARCH_ENGINES: 'Search engines',
    IMAGE_AND_VIDEO_SEARCH: 'Image and Video search',
    JOB_SEARCH: 'Job search',
    TECHNOLOGY: 'Technology',
    COMPUTER_AND_INTERNET_SECURITY: 'Computer and Internet security',
    CONTENT_DELIVERY_NETWORKS: 'Content delivery networks',
    DYNAMICALLY_GENERATED_CONTENT: 'Dynamically generated content',
    INDIVIDUAL_STOCK_ADVICE_AND_TOOLS: 'Individual stock advice and tools',
    INTERNET_COMMUNICATIONS: 'Internet communications',
    PERSONAL_STORAGE: 'Personal storage',
    SHAREWARE_AND_FREEWARE: 'Shareware and Freeware',
    TRANSLATION: 'Translations',
    WEB_HOSTING: 'Web hosting',
    WEB_BASED_EMAIL: 'Web based email',
    UNWANTED_WEBSITES: 'Unwanted websites',
    ABORTION: 'Abortion',
    ABUSED_DRUGS: 'Abused drugs',
    ADULT_AND_PORNOGRAPHY: 'Adult and Pornography',
    ALCOHOL_AND_TOBACCO: 'Alcohol and Tobacco',
    AUCTIONS: 'Auctions',
    CHEATING: 'Cheating',
    CULT_AND_OCCULT: 'Cult and Occult',
    DATING: 'Dating',
    DEAD_SITES: 'Dead sites',
    GAMES: 'Games',
    GROSS: 'Gross',
    HATE_AND_RACISM: 'Hate and Racism',
    ILLEGAL: 'Illegal',
    MARIJUANA: 'Marijuana',
    MILITARY: 'Military',
    NUDITY: 'Nudity',
    PRIVATE_IP_ADDRESSES: 'Private IP Addresses',
    SEX_EDUCATION: 'Sex education',
    SWIMSUITS_AND_INTIMATE_APPAREL: 'Swimsuits and Intimate apparel',
    VIOLENCE: 'Violence',
    WEAPONS: 'Weapons',
    WORK: 'Work',
    BUSINESS_AND_ECONOMY: 'Business and Economy',
    EDUCATIONAL_INSTITUTIONS: 'Educational institutions',
    FINANCIAL_SERVICES: 'Financial services',
    GOVERNMENT: 'Government',
    HEALTH_AND_MEDICINE: 'Health and Medicine',
    LEGAL: 'Legal',
    REFERENCE_AND_RESEARCH: 'Reference and Research',
    TRAINING_AND_TOOLS: 'Training and Tools',
    SELF_HARM: 'Self Harm',
    DNS_OVER_HTTPS: 'DNS Over HTTPS',
    LOW_THC_CANNABIS_PRODUCTS: 'Low-THC Cannabis Products',
    GENERATIVE_AI: 'Generative AI',
    CATEGORIES: 'Categories',
    CUSTOM_CATEGORIES: 'Custom categories',
    CATEGORY_NAME: 'Name',
    CATEGORY_COUNT: 'Count',
    CATEGORY_STATE: 'State',
    ERROR_INDEX: 'Error while obtaining categories',
    SUCCESS_LEVEL_SET: 'Protection level updated successfully',
    ERROR_LEVEL_SET: 'Error while updating protection level',
    ERROR_UPDATING_CATEGORY: 'Error while updating category',
    ERROR_UPDATING_SUBCATEGORY: 'Error while updating subcategory',
    BASIC_BLOCKED:
      'BLOCKED CATEGORIES: Malware Sites, Phishing and Other Frauds, Spyware and Adware, Hidden Proxies and Anonymizers, Confirmed SPAM Sources, Gambling, Repulsive content, Keyloggers and Monitoring, Cheating, Illegal, Violence, Bot Nets, Hacking, Weapons',
    MEDIUM_BLOCKED:
      'BLOCKED CATEGORIES: Malware Sites, Phishing and Other Frauds, Spyware and Adware, Hidden Proxies and Anonymizers, Confirmed SPAM Sources, Gambling, Repulsive content, Keyloggers and Monitoring, Cheating, Illegal, Violence, Bot Nets, Hacking, Travel, Drugs, Games, Marijuana, Spam URLs, Parked Domains, Weapons',
    HIGH_BLOCKED:
      'BLOCKED CATEGORIES: Malware Sites, Phishing and Other Frauds, Spyware and Adware, Hidden Proxies and Anonymizers, Confirmed SPAM Sources, Gambling, Repulsive content, Keyloggers and Monitoring, Cheating, Illegal, Violence, Bot Nets, Hacking, Travel, Drugs, Games, Marijuana, Spam URLs, Parked Domains, Auctions, Shopping, Cult and Occult, Streaming Media, Weapons, Online Greeting Cards, Hate and Racism, Alcohol and Tobacco, Social Networking',
    WILDCARD: 'Wildcard',
    REGEX: 'Regular expression',
    EXPRESSION_TYPE: 'Expression type',
    URLS: 'Urls',
    ADD_CUSTOM_CATEGORY: 'Add custom category',
    EDIT_CUSTOM_CATEGORY: 'Edit custom category',
    SUCCESS_DELETE: 'Custom category deleted successfully',
    ERROR_DELETE: 'Error deleting custom category',
    SUCCESS_CREATE: 'Custom category created successfully',
    ERROR_CREATE: 'Error creating custom category',
    SUCCESS_UPDATE: 'Custom category updated successfully',
    ERROR_UPDATE: 'Error updating custom category',
    CONTENT_FILTER: 'Content Filter',
    WEB_CONTENT_FILTERS: 'Web Content Filters',
    SUCCESS_CONTENT_FILTER_SET: 'Content filter updated successfully',
    ERROR_CONTENT_FILTER_SET: 'Error while updating content filter',
    ERROR_INDEX_CONTENT_FILTER: 'Error while obtaining web content filters',
    ERROR_UPDATE_CONTENT_FILTER: 'Error updating content filter',
    SUCCESS_UPDATE_CONTENT_FILTER: 'Content filter updated successfully',
    SUCCESS_DELETE_CONTENT_FILTER: 'Content filter deleted successfully',
    ERROR_DELETE_CONTENT_FILTER: 'Error deleting content filter',
    SUCCESS_CREATE_CONTENT_FILTERS: 'Content filter created successfully',
    ERROR_CREATE_CONTENT_FILTERS: 'Error creating content filter',
    EDIT_CONTENT_FILTER: 'Edit Content Filter',
    ADD_CONTENT_FILTER: 'Add Content Filter',
    REPUTATION: 'Minimum reputation allowed',
    SUCCESS_REPUTATION_SET: 'Reputation updated successfully',
    ERROR_REPUTATION_SET: 'Error while updating reputation',
    ALLOW_ALL: 'Allow all',
    URLS_DESCRIPTION:
      'Wildcard *: URL with wildcard symbol optionally at the begining or end of the URL. The wildcard symbol represents 0 or more unknown or variable characters\nRegex (.*): URL regular expresion following the PCRE convention',
    BAD_WILDCARD_EXPRESSION:
      'Bad wildcard expression: if the wildcard character (*) is present it must be at the beginning or end of the expression',
    SEARCH_PROTECTION: 'Google®, Bing®, Yahoo® and Youtube® Search Protection',
    SEARCH_PROTECTION_HELP:
      'Turn on Google/Bing safe search to limit search results (web pages, images and content), only safe and appropriate results will be shown. Pornography and other undesired content will not be included in the search results',
    SUCCESS_SEARCH_PROTECTION_SET: 'Search protection updated successfully',
    ERROR_SEARCH_PROTECTION_SET: 'Error while updating search protection',
    MODERATE: 'Moderate',
    STRICT: 'Strict',
    YOUTUBE_MODE: 'Youtube restriction',
    SUCCESS_YOUTUBE_MODE_SET: 'Youtube restriction level updated successfully',
    ERROR_YOUTUBE_MODE_SET: 'Error while updating Youtube restriction level',
  },
  IPS_EXCEPTION: {
    ORIGIN_IP: 'Origin IP',
    PORT: 'Port',
    DESTINATION_IP: 'Destination IP',
    ORIGIN: 'Origin',
    DESTINATION: 'Destination',
    ACTIONS: 'Actions',
  },
  TEMPLATES: {
    TITLE: 'CLI Extension',
    SUCCESS_CREATE: 'Template created successfully',
    ERROR_CREATE: 'Error while creating template',
    SUCCESS_UPDATE: 'Template updated successfully',
    ERROR_UPDATE: 'Error while updating template',
    TEMPLATE: 'Template',
    EDIT: 'Edit Template',
    ADD: 'Add Template',
    SUCCESS_CLI: 'CLI extension updated successfully',
    ERROR_CLI: 'Error while updating CLI extension',
    SUCCESS_DELETE: 'Template deleted successfully',
    ERROR_DELETE: 'Error while deleting template',
  },
  SYSLOGS: {
    DUPLICATED_NAME: 'The name cannot be duplicated',
    WRONG_PORT: 'Port must be a number between 1 and 65535',
    WRONG_PROTOCOL: 'Protocol must be TCP or UDP',
    WRONG_HOST: 'Incorrect host',
    MAXIMUM_REACHED: 'Only 4 syslog entries are allowed',
    NAME: 'Name',
    HOST: 'Host',
    PROTOCOL: 'Protocol',
    PORT: 'Port',
    ACTIONS: 'Actions',
    SUCCESS_DELETE: 'Syslog deleted successfully',
    ERROR_DELETE: 'Error while deleting syslog',
    EDIT: 'Edit syslog',
    ADD: 'Create syslog',
    ERROR_UPDATE: 'Error while updating syslog',
    SUCCESS_UPDATE: 'Syslog updated successfully',
    ERROR_CREATE: 'Error while creating syslog',
    SUCCESS_CREATE: 'Syslog created successfully',
    CA_FILE: 'CA file',
    CA_FILE_HELP:
      'This field can be empty if you own a certificate signed by a Certificate Authority (CA)',
    UPLOAD_CA_FILE: 'Upload a CA certificate',
    REQUIRE_CA_FILE: 'CA file is required for this configuration',
    ENCRYPTED: 'Enable encryption',
    ENCRYPTED_COLUMN: 'Encrypted',
    TRUST_UNKNOWN_CA: 'Trust on unknown CAs',
    MUTUAL_TLS: 'Enable mutual TLS',
    MUTUAL_TLS_COLUMN: 'Mutual TLS',
    MUTUAL_TLS_HELP:
      'A client certificate is required in order to use mutual TLS',
    CLIENT_CERT: 'Client certificate',
    CLIENT_KEY: 'Client key',
    UPLOAD_CLIENT_CERT: 'Upload a client certificate',
    REQUIRE_CLIENT_CERT:
      'Client certificate is required for this configuration',
    UPLOAD_CLIENT_KEY: 'Upload a key certificate',
    REQUIRE_CLIENT_KEY: 'Client key is required for this configuration',
  },
  FORWARD_SYSLOGS: {
    MAXIMUM_REACHED: 'Only 3 syslog entries are allowed',
    ENCRYPTED: 'Encrypted',
    ENCRYPTED_HELP:
      'A certificate is required in order to use encryption. You can add one in the Administration section.',
  },
  VPNTUNNEL: {
    CONFIRM_DELETE: 'Do you wish to delete the tunnel?',
    DELETE_SUCCESS: 'Tunnel deleted successfully',
    DELETE_ERROR: 'Error while deleting tunnel',
    NAME: 'Name',
    SOURCE: 'IP Pool',
    INTERFACE: 'Interface',
    PORT: 'Port',
    PROTOCOL: 'Protocol',
    ENABLE_SPLIT_TUNNELING: 'Enable split tunneling',
    SPLIT_TUNNELING: 'Split tunneling',
    SUCCESS_CREATE: 'Tunnel created successfully',
    ERROR_CREATE: 'Error while creating tunnel',
    SUCCESS_UPDATE: 'Tunnel updated successfully',
    ERROR_UPDATE: 'Error while updating tunnel',
    EDIT_TUNNEL: 'Edit tunnel',
    ADD_TUNNEL: 'Create tunnel',
    ROUTING_ADDRESS: 'Routing Address',
    DNS1: 'DNS primary',
    DNS2: 'DNS secondary',
    AUTHENTICATION: 'Authentication',
    CONNECTION_SETTINGS: 'Connection Settings',
    TUNNEL_AUTHENTICATION: 'Tunnel mode and Authentication',
    DNS: 'DNS',
    UPLOAD_CLIENT_CERT: 'Upload client certificate',
    USERS: 'Users',
    SERVER_DEFINITION: 'Server definition',
    DESTINATION_SERVER: 'SSL Destination Server',
    IP: 'IP',
    HOSTNAME: 'Hostname',
    INVALID_PORT: 'Port is not valid',
    INVALID_PROTOCOL: 'Protocol is not valid',
    INVALID_DNS: 'DNS is not valid',
    INVALID_IP: 'IP is not valid',
    INVALID_HOSTNAME: 'Hostname is not valid',
    NO_USERS_ERROR: 'No users assigned to the tunnel',
    ANY_NETWORK_PRESENT_ERROR: 'The Any network is present in the tunnel',
    DISABLED_APPLICATIONS: 'Disabled applications',
    IP_POOL_ROUTING_ADDRESS_ERROR:
      'IP Pool and Routing Address must be different',
    MISSING_USER_EMAIL_ERROR: 'Users must have an assigned email',
    IP_POOL_EXHAUSTED: 'There are no available IP addresses in the pool',
    PORT_PROTOCOL_DUPLICATED:
      'The protocol/port combination is being used in another tunnel',
    IP_POOL_DUPLICATED: 'The IP Pool is being used in another tunnel',
    SSL_DESTINATION_SERVER_DUPLICATED:
      'The SSL Destination Server is being used in another tunnel',
    DUPLICATED_NAME: 'The name is already in use in another tunnel',
  },
  REMOTE_ACCESS: {
    ONLINE_USERS: 'Online users',
    CONCURRENT_USERS_WARNING:
      'The maximum number of concurrent users allowed by the licenses has been exceeded.',
  },
  SSLVPN: {
    CONFIRM_DELETE: 'Do you wish to delete the tunnel?',
    DELETE_SUCCESS: 'Tunnel deleted successfully',
    DELETE_ERROR: 'Error while deleting tunnel',
    NAME: 'Name',
    SOURCE: 'IP Pool',
    INTERFACES: 'Interfaces',
    PORT: 'Port',
    ENABLE_SPLIT_TUNNELING: 'Enable split tunneling',
    SPLIT_TUNNELING: 'Split tunneling',
    TITLE: 'SSL VPN',
    SUCCESS_CREATE: 'Tunnel created successfully',
    ERROR_CREATE: 'Error while creating tunnel',
    SUCCESS_UPDATE: 'Tunnel updated successfully',
    ERROR_UPDATE: 'Error while updating tunnel',
    EDIT_TUNNEL: 'Edit tunnel',
    ADD_TUNNEL: 'Create tunnel',
    ROUTING_ADDRESS: 'Routing Address',
    DNS1: 'DNS primary',
    DNS2: 'DNS secondary',
    AUTHENTICATION: 'Authentication method',
    CONNECTION_SETTINGS: 'Connection Settings',
    TUNNEL_AUTHENTICATION: 'Tunnel mode and Authentication',
    DNS: 'DNS',
    UPLOAD_CLIENT_CERT: 'Upload CA certificate',
    BUILD_EXE: 'Windows Build',
    SUCCESS_BUILD:
      'Build initiated successfully. The executable will be available in a few minutes. Please wait for the process to complete.',
    BUILD_STATUS:
      'A build is currently in progress. Please attempt the bulk creation after 5 minutes',
    NO_USERS: 'There are no users assigned to the tunnel',
    CLIENT_CERT: "User's Certificate",
    USERS: "User's Credentials",
    USER_LIST: 'User List',
    ALREADY_IN_USE: 'The SSL VPN tunnel is being used in other place',
  },
  IPSECVPN_REMOTE: {
    CONFIRM_DELETE: 'Do you wish to delete the tunnel?',
    DELETE_SUCCESS: 'Tunnel deleted successfully',
    DELETE_ERROR: 'Error while deleting tunnel',
    NAME: 'Name',
    SOURCE: 'IP Pool',
    INTERFACES: 'Interfaces',
    PORT: 'Port',
    ENABLE_SPLIT_TUNNELING: 'Enable split tunneling',
    SPLIT_TUNNELING: 'Split tunneling',
    TITLE: 'IPSEC Vpn',
    SUCCESS_CREATE: 'Tunnel created successfully',
    ERROR_CREATE: 'Error while creating tunnel',
    SUCCESS_UPDATE: 'Tunnel updated successfully',
    ERROR_UPDATE: 'Error while updating tunnel',
    EDIT_TUNNEL: 'Edit tunnel',
    ADD_TUNNEL: 'Create tunnel',
    BUILD_EXE: 'Windows Build',
    SUCCESS_BUILD:
      'Build initiated successfully. The executable will be available in a few minutes. Please wait for the process to complete.',
    BUILD_STATUS:
      'A build is currently in progress. Please attempt the bulk creation after 5 minutes',
    NO_USERS: 'There are no users assigned to the tunnel',
  },
  VPN_USERS: {
    TITLE: 'Users',
    SUCCESS_CREATE: 'User created successfully',
    ERROR_CREATE: 'Error while creating user',
    SUCCESS_UPDATE: 'User updated successfully',
    ERROR_UPDATE: 'Error while updating user',
    EDIT_USER: 'Edit User',
    ADD_USER: 'Create User',
    CONFIRM_DELETE: 'Are you sure you want to delete the user?',
    DELETE_SUCCESS: 'User deleted successfully',
    DELETE_ERROR: 'Error while deleting user',
    EMAIL: 'Email',
    GROUPS: 'Groups',
    GLOBAL_SETTINGS: 'Users Settings',
    LOCAL: 'Local users',
  },
  TENANT: {
    ID: 'ID',
    IPS_TYPE: 'IPS Type',
    IPS_TYPE_ADVANCED: 'Advanced',
    IPS_TYPE_BASIC: 'Basic',
    TYPE: 'Type',
    DUPLICATED_EMAIL: 'Email is already in use',
    DUPLICATED_NAME: 'Tenant name is already in use',
    SUCCESS: 'Tenant created succesfully',
    DETAILS: 'Tenant details',
    ADMINISTRATOR: 'Administrator',
    CLOUD_DEVICES: 'Cloud Hostname',
    UUID: 'UUID',
    ADD_CLOUD_DEVICE: 'Add Cloud device',
    CLOUD_IP: 'IP',
    UPDATE_SUCCESS: 'Tenant updated successfully',
    UPDATE_ERROR: 'Error while updating tenant',
    NAME: 'Name',
  },
  VPN_USER_GROUPS: {
    TITLE: 'User Groups',
    SUCCESS_CREATE: 'Group created successfully',
    ERROR_CREATE: 'Error while creating group',
    SUCCESS_UPDATE: 'Group updated successfully',
    ERROR_UPDATE: 'Error while updating group',
    EDIT_GROUP: 'Edit Group',
    ADD_GROUP: 'Create Group',
    CONFIRM_DELETE: 'Are you sure you want to delete the group?',
    DELETE_SUCCESS: 'Group deleted successfully',
    DELETE_ERROR: 'Error while deleting group',
    LOCAL_GROUPS: 'User groups',
    USERS: 'Users',
  },
  HELP_MODAL: {
    DEVICE_EXPECTED_HEADERS: 'Expected format of CSV Headers (comma separated)',
    DEVICE_EXPECTED_ROWS: 'Rows (comma separated)',
    DEVICE_ROWS:
      'Device Name,Teldat,Device serial number,Device description,Device model*,device dvc,ip,licenses**',
    DEVICE_POSSIBLE_MODELS:
      '* Device model possible values: RS420, Celer, M2, M10, Regesta, Atlas 840, H5-Rail, H5-Auto, VM',
    DEVICE_LICENSES_LEGEND: '** Licenses legend:',
    DEVICE_POSSIBLE_LICENSES:
      "Possible values (separated by ';'): UTM_R_AS, UTM_M_AS, UTM_A_AS, UTM_V_AS, UTM_R_AV, UTM_M_AV, UTM_A_AV, UTM_V_AV, UTM_R_AC, UTM_M_AC, UTM_A_AC, UTM_V_AC, UTM_R_DLP, UTM_M_DLP, UTM_A_DLP, UTM_V_DLP, UTM_R_FW, UTM_M_FW, UTM_A_FW, UTM_V_FW, UTM_R_IPS, UTM_M_IPS, UTM_A_IPS, UTM_V_IPS, UTM_R_IPSOT, UTM_M_IPSOT, UTM_A_IPSOT, UTM_V_IPSOT, UTM_R_SSL, UTM_M_SSL, UTM_A_SSL, UTM_V_SSL, UTM_R_ZTNA, UTM_M_ZTNA, UTM_A_ZTNA, UTM_V_ZTNA, UTM_R_RA, UTM_M_RA, UTM_A_RA, UTM_V_RA, UTM_R_UF, UTM_M_UF, UTM_A_UF, UTM_V_UF, UTM_R_CB, UTM_M_CB, UTM_A_CB, UTM_V_CB, UTM_R_DNS, UTM_M_DNS, UTM_A_DNS, UTM_V_DNS, UTM_R_UID, UTM_M_UID, UTM_A_UID, UTM_V_UID, UTM_R_VPNTUN, UTM_M_VPNTUN, UTM_A_VPNTUN, UTM_V_VPNTUN",
  },
  DNS_FORWARDER: {
    INVALID_DNS_1: 'DNS Forwarder primary is not valid',
    INVALID_DNS_2: 'DNS Forwarder secondary is not valid',
    BLOCK_DISABLE_SAFE_SEARCH:
      'DNS Forwarder cannot be disabled while the device is associated with a Firewall profile with Search Protection enabled',
  },
  DNS_RESOLVER: {
    INVALID_DNS_1: 'DNS Resolver primary is not valid',
    INVALID_DNS_2: 'DNS Resolver secondary is not valid',
    BLOCK_DISABLE: 'DNS Resolver cannot be disabled',
  },
  TRUST: {
    VERY_LOW: 'Very Low',
    LOW: 'Low',
    MEDIUM: 'Medium',
    HIGH: 'High',
    VERY_HIGH: 'Very High',
  },
  ACTIONS: {
    ALLOW: 'Allow',
    DETECT: 'Detect',
    BLOCK: 'Block',
  },
  CASB_RULES: {
    ADD: 'Created',
    CONFIRM_DELETE: 'Are you sure you want to delete this CASB rule?',
    DUPLICATED_NAME: 'Name already registered',
    EDIT: 'Updated',
    ERROR_ADD: 'Error while creating CASB rule',
    ERROR_EDIT: 'Error while updating CASB rule',
    SUCCESS_ADD: 'Successfully created CASB rule',
    SUCCESS_DELETE: 'Successfully deleted CASB rule',
    SUCCESS_EDIT: 'Successfully updated CASB rule',
  },
  DLP_OBJECTS: {
    NAME: 'Name',
    DESCRIPTION: 'Description',
    EXPRESSION: 'Expression',
    EDIT: 'Edit DLP object',
    ADD: 'Add DLP object',
    EDIT_SUCCESS: 'Successfully updated DLP object',
    EDIT_ERROR: 'Error while updating DLP object',
    ADD_SUCCESS: 'Successfully added DLP object',
    ADD_ERROR: 'Error while creating DLP object',
    LOAD_ERROR: 'Error while loading DLP objects',
    ALREADY_IN_USE: 'Already in use',
    DELETE_SUCCESS: 'Successfully deleted DLP object',
  },
  DLP_RULES: {
    EDIT_SUCCESS: 'Successfully updated DLP rule',
    EDIT_ERROR: 'Error while updating DLP rule',
    ADD_SUCCESS: 'Successfully added DLP rule',
    ADD_ERROR: 'Error while creating DLP rule',
    LOAD_ERROR: 'Error while loading DLP rules',
    DELETE_SUCCESS: 'Successfully deleted DLP rule',
    NAME: 'DLP Rules',
  },
  PROTOCOL_PORTS: {
    UNWANTED_CHARACTERS:
      'The ports and/or range ports have a wrong format. Valid example: "60, 80-90, 100".',
    NOT_IN_RANGE: 'One or more ports specified are not valid ports',
    WRONG_PORT_RANGE:
      'In port ranges, the second port must be greater than the first one. Valid example: "80-90".',
  },
  SSL_EXCEPTION: {
    DUPLICATE_NAME: 'Name already registered',
    EMPTY_NAME: 'Empty Name',
  },
  IPSEC_VPN: {
    TITLE: 'IPSec VPN L2L Tunnels',
    PHASE1: 'Phase 1',
    ENCRYPTION: 'Encryption',
    HASH: 'Hash',
    MAXIMUM_PROPOSALS_REACHED: 'Only 20 Proposals are allowed',
    MAX_SECOND_PHASES: 'Only 10 Second phases are allowed',
    CONFIRM_DELETE: 'Do you wish to delete the tunnel?',
    DELETE_SUCCESS: 'Tunnel deleted successfully',
    DELETE_ERROR: 'Error while deleting tunnel',
    SUCCESS_CREATE: 'Tunnel created successfully',
    ERROR_CREATE: 'Error while creating tunnel',
    SUCCESS_UPDATE: 'Tunnel updated successfully',
    ERROR_UPDATE: 'Error while updating tunnel',
    EDIT_TUNNEL: 'Edit tunnel',
    ADD_TUNNEL: 'Create tunnel',
    REMOTE_PEER: 'Remote peer',
    LOCAL_INTERFACE: 'Local interface',
    HOSTNAME: 'Hostname',
    IP: 'IP',
    IKE: 'IKE Version',
    DEAD_PEER_DETECTION: 'Dead Peer Detection',
    ACTION: 'Action',
    INTERVAL: 'Interval (1-86400 secs)',
    TIMEOUT: 'Timeout (1-86400 secs)',
    AUTHENTICATION: 'Authentication',
    PRESHARED_KEY: 'Preshared key',
    LOCAL_ID: 'Local ID',
    REMOTE_ID: 'Remote ID',
    PROPOSALS: 'Proposals',
    DIFFIE_HELLMAN_GROUPS: 'Diffie Hellman Groups',
    IKE_LIFETIME: 'IKE Lifetime (30-86400)',
    IKE_MODE: 'IKE Mode',
    PHASE2: 'Phase 2',
    LOCAL_SELECTOR: 'Local selector',
    REMOTE_SELECTOR: 'Remote selector',
    PERFECT_FORWARD_SECRECY: 'Perfect Forward Secrecy',
    MODE: 'Mode',
    LIFETIME_TYPE: 'Lifetime Type',
    LIFETIME: 'Lifetime',
    COMPRESSION: 'Compression',
    TUNNEL: 'Tunnel',
    TRANSPORT: 'Transport',
    SECONDS: 'Seconds',
    MB: 'MB',
    PACKETS: 'Packets',
    AES128: 'AES-128 encryption with CBC *',
    AES192: 'AES-192 encryption with CBC *',
    AES256: 'AES-256 encryption with CBC *',
    AES128GCM128: 'AES-128 encryption with Galois Counter Mode 128-bit',
    AES192GCM128: 'AES-192 encryption with Galois Counter Mode 128-bit',
    AES256GCM128: 'AES-256 encryption with Galois Counter Mode 128-bit',
    AES128GMAC:
      'Null encryption with AES-128 Galois Message Authentication Code',
    AES192GMAC:
      'Null encryption with AES-192 Galois Message Authentication Code',
    AES256GMAC:
      'Null encryption with AES-256 Galois Message Authentication Code',
    AES192GCM64: 'AES-192 encryption with GCM and 64 bit ICV',
    AES128CCM64: 'AES-128 encryption with CCM and 64 bit ICV',
    AES192CCM64: 'AES-192 encryption with CCM and 64 bit ICV',
    AES256CCM64: 'AES-256 encryption with CCM and 64 bit ICV',
    '3DES': '3DES encryption',
    CHACHA20POLY1305: 'ChaCha20-Poly1305 encryption',
    NULL: 'Null encryption',
    MD5: 'MD5 hash',
    SHA1: 'SHA1 hash',
    SHA256: 'SHA2-256 hash',
    SHA384: 'SHA2-384 hash',
    SHA512: 'SHA2-512 hash',
    ENCRYPTION_NOTE: 'Hardware offloaded encryption algorythm',
    ADD_TUNNEL_ABOVE: 'Add tunnel above',
    ADD_TUNNEL_BELOW: 'Add tunnel below',
    ADD_TUNNEL_TOP: 'Add tunnel to top',
    ADD_TUNNEL_BOTTOM: 'Add tunnel bottom',
    PHASE_2_REMOTE_PREFIX: 'Phase 2 remote prefix',
    WRONG_INTERVAL: 'Interval value must be between 1 and 86400',
    WRONG_TIMEOUT: 'Timeout value must be between 1 and 86400',
    WRONG_IKE_LIFETIME: 'IKE lifetime value must be between 30 and 86400',
    WRONG_LIFETIME: 'Lifetime value must be between 0 and 4294967295',
    IPSEC_MODE: 'IPSec Mode',
    ROUTE_BASED: 'Route Based',
    POLICY_BASED: 'Policy Based',
    VIRTUAL_TUNNEL_INTERFACE_NAME: 'Virtual Tunnel Interface Name',
    ADD_ROUTE_AUTO: 'Automatically add VPN PBR',
    CONNECTION_TYPE: 'Connection Type',
    ON_DEMAND: 'On Demand',
    INITIATE: 'Initiate',
    RESPOND: 'Respond',
    ANY: '%any',
    ANY_TOOLTIP: "Only active when connection type is 'Respond'",
    PROTECTED_TUNNEL: 'The tunnel cannot be edited because it is protected',
  },
  USER_LOGINS: {
    EMAIL: 'User email',
    DATE: 'Date',
    RESULT: 'Result',
    REMOTE_ADDRESS: 'Remote address',
    LOGIN_ERROR: 'Login has failed',
  },
  LIST_MODAL: {
    SOURCE_SELECT: 'Select Source',
    CERTIFICATE_SELECT: 'Select Certificate',
  },
  USER_MANUAL: {
    CARRIER_FILENAME: 'v1.1.0_carrier_en.pdf',
    UTM_FILENAME: 'v1.1.2_utm_en.pdf',
  },
  THREAT_INTEL: {
    VERSION: 'Version',
    LAST_UPDATE: 'Last version update',
    LAST_CHECK: 'Last version check',
    CLOUD: 'Cloud',
    MANAGED_DEVICES: 'Managed devices',
  },
  BESAFE_GROUPS: {
    INVALID_ASSOCIATED_OBJECTS: 'Invalid associated objects for the group',
    INVALID_EDITABLE: 'Invalid editable for the group',
    INVALID_IP_PROTOCOL: 'Invalid IP protocol for the group',
    INVALID_TYPE: 'Invalid type for the group',
    MISSING_ASSOCIATED_OBJECTS: 'Missing associated objects for the group',
    MISSING_IP_PROTOCOL: 'Missing IP protocol for the group',
    MISSING_NAME: 'Missing name for the group',
    MISSING_TYPE: 'Missing type for the group',
  },
  ZTNA: {
    RULES: 'Rules',
    CONNECTOR: 'Connector',
    CONNECTORS: 'Connectors',
    APPLICATION: 'Application',
    APPLICATIONS: 'Applications',
    ADD_RULE_TITLE: 'Add new ZTNA rule',
    ADD_SUCCESS: 'ZTNA rule added successfully',
    ADD_ERROR: 'Error while adding ZTNA rule',
    EDIT_RULE_TITLE: 'Edit ZTNA rule',
    EDIT_SUCCESS: 'ZTNA rule updated successfully',
    EDIT_ERROR: 'Error while updating ZTNA rule',
    DELETE_SUCCESS: 'ZTNA rule deleted successfully',
    DELETE_ERROR: 'Error while deleting ZTNA rule',
    ADD_RULE_ABOVE: 'Add ZTNA rule above',
    ADD_RULE_BELOW: 'Add ZTNA rule below',
    ADD_RULE_TOP: 'Add ZTNA rule to top',
    ADD_RULE_BOTTOM: 'Add ZTNA rule bottom',
    SSL_PROFILE_PROTOCOL_ERROR: 'SSL Inspection can only be used with HTTPS',
    CONFIRM_DELETE: 'Are you sure you want to delete the ZTNA rule?',
    MISSING_ZTNA_APPLICATIONS: 'ZTNA application is required',
    ERROR_APPLICATIONS_NUMBER:
      'Only one application can be associated to the ZTNA rule',
    CONNECTOR_IN_RULE: 'The connector is associated to a ZTNA rule',
    TUNNEL_IN_RULE: 'The tunnel is associated to a ZTNA rule',
    REQUIRED_SOURCES_ERROR:
      'The ZTNA rule must have either a SSL VPN tunnel, a user object or a user group as Source',
    MIXING_SOURCES_ERROR:
      'The ZTNA rule must not combine SSL VPN tunnels with users as Source',
    CONNECTOR_SELECT: 'Select Connector',
    APPLICATION_SELECT: 'Select Application',
  },
  ZTNA_APPLICATIONS: {
    REQUIRED_NAME: 'Name required',
    REQUIRED_SERVICE: 'Service required',
    REQUIRED_IP: 'IP required',
    REQUIRED_URL: 'URL required',
    DELETE_TITLE: 'Delete ZTNA Application',
    DELETE_DESCRIPTION:
      'Are you sure you want to delete the ZTNA Application {name}?',
    SUCCESS_DELETE: 'ZTNA Application deleted successfully',
    TABLE_TITLE: 'ZTNA Applications',
    NAME: 'Name',
    DESCRIPTION: 'Description',
    URL: 'URL',
    IP: 'IP',
    SERVICE: 'Service',
    ADD: 'Add ZTNA Application',
    EDIT: 'Edit ZTNA Application',
    SUCCESS_ADD: 'ZTNA Application added successfully',
    SUCCESS_EDIT: 'ZTNA Application updated successfully',
    ERROR_ADD: 'Error while adding ZTNA Application',
    ERROR_EDIT: 'Error while updating ZTNA Application',
    DUPLICATED_NAME: 'Name already registered',
    INVALID_IP: 'Invalid IP',
    INVALID_URL: 'Invalid URL',
    CANNOT_DELETE_WITH_RULES: 'Cannot delete application with associated rules',
  },
};

export default flatten(messages);
