import React, { useState, useEffect, useCallback } from "react";
import { Modal, Table, Button, Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import { getCertificates } from 'app/crud/certificates.crud';
import NoDataDisplay from "components/commons/NoDataDisplay";
import TableLoader from "components/commons/TableLoader";
import BeSafeButton from "components/commons/BeSafeButton";
import BeSafeSearchBar from "components/commons/BeSafeSearchBar";
import BeSafeSeparator from "components/commons/BeSafeSeparator";

const CertificateListSelect = (props) => {
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(
    props.initialSelected || null
  );
  const [loading, setLoading] = useState(false);

  const [query, setQuery] = useState('');

  const selectRows = (item) => {
    setSelectedItem(item);
  };

  const handleSubmit = () => {
    if (_.isFunction(props.changeItems)) {
      props.changeItems(selectedItem);
      handleHide();
    }
  };

  const handleHide = () => {
    setQuery('');
    setSelectedItem(null);
    props.onHide();
  };

  const fetchItems = useCallback((query) => {
    setLoading(true);
    if (props.showModal) {
      getCertificates({
        q: query,
        filter_expired: true,
      })
        .then((response) => {
          setItems(response.data.certificates);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [props.showModal]);

  useEffect(() => {
    if (props.showModal) fetchItems(query);
  }, [props.showModal, fetchItems, query]);

  useEffect(() => {
    if (props.showModal && props.initialSelected) setSelectedItem(props.initialSelected);
  }, [props.showModal, props.initialSelected]);

  const selectItemOnClick = (item) => {
    setSelectedItem(item);
  };

  const getItemName = (item) => {
    return `${item.name}, ${item.subject}`;
  };

  return (
    <>
      <Modal
        show={props.showModal}
        onHide={handleHide}
        size="lg"
        centered
        dialogClassName="modal-shadow-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="LIST_MODAL.CERTIFICATE_SELECT" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="pt-3">
          <div className="w-100 d-flex align-items-center">
            <div className="w-100">
              <BeSafeSearchBar
                onSearch={setQuery}
              />
            </div>
          </div>
          <BeSafeSeparator />
          <TableLoader rows={8} visible={loading} />
          <NoDataDisplay visible={!loading && items.length <= 0} />
          {!loading && items.length > 0 && (
            <div
              className="modal-selection-list overflow-auto"
            >
              <Table className="mb-0 table-borderless" hover>
                <tbody>
                  {items.map((item, index) => (
                    <tr key={index}>
                      <td
                        className="border-bottom"
                        onClick={() => selectItemOnClick(item)}
                      >
                        <div className="d-flex justify-content-between">
                          <Form.Group className="mb-0 d-flex justify-content-between align-items-center w-100">
                            <Form.Check
                              id={item.id}
                              className="mb-0"
                              type="radio"
                            >
                              <Form.Check.Input
                                name="listRadio"
                                type="radio"
                                isValid
                                value={item.id}
                                checked={selectedItem && selectedItem.id === item.id}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => selectRows(item)}
                              />
                              <Form.Check.Label
                                onClick={(e) => e.stopPropagation()}
                              >
                                <span>{getItemName(item)}</span>
                              </Form.Check.Label>
                            </Form.Check>
                          </Form.Group>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="py-3">
          <Button variant="transparent" onClick={props.onHide}>
            <FormattedMessage id="OBJECT_MODAL.CANCEL" />
          </Button>
          <BeSafeButton
            variant="primary"
            type="button"
            onClick={handleSubmit}
            text={<FormattedMessage id="GENERAL.SAVE" />}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CertificateListSelect;
