import axios from "axios";

const PROFILES_URL = "/profiles";
const BASE_URL = "/vpn_tunnels";

export function getVpnTunnels(profileId, params) {
  return axios.get(`${PROFILES_URL}/${profileId}${BASE_URL}`, {params});
}

export function deleteTunnel(profileId, tunnelId) {
  return axios.delete(`${PROFILES_URL}/${profileId}${BASE_URL}/${tunnelId}`);
}

export function createVpnTunnel(profileId, params) {
  return axios.post(`${PROFILES_URL}/${profileId}${BASE_URL}`, params);
}

export function updateVpnTunnel(profileId, tunnelId, params) {
  return axios.patch(`${PROFILES_URL}/${profileId}${BASE_URL}/${tunnelId}`, params);
}

