import axios from "axios";

const BASE_URL = "/applications";

export function findFamilies(profileId, params) {
  return axios.get(`/profiles/${profileId}${BASE_URL}/categories`, { params });
}

export function updateApplicationsAction(profileId, params) {
  return axios.patch(`/profiles/${profileId}${BASE_URL}/categories`, params);
}

export function findAllByCategory(profileId, params) {
  return axios.get(`/profiles/${profileId}${BASE_URL}`, { params });
}

export function changeSingleApplicationState(profileId, applicationId, params) {
  return axios.patch(
    `/profiles/${profileId}${BASE_URL}/${applicationId}`,
    params
  );
}

export function searchCategories(profileId, params) {
  return axios.get(`/profiles/${profileId}${BASE_URL}/search`, { params });
}

export function findAllApplications() {
  return axios.get(`${BASE_URL}/all`);
}

export function fetchAllApplications(params) {
  return axios.get(`${BASE_URL}/fetch_all_applications`, {params});
}

export function fetchCategories() {
  return axios.get(`${BASE_URL}/get_categories`);
}
