import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form } from 'react-bootstrap';
import { Download, WorkspacePremium } from '@mui/icons-material';

import { downloadCertificate } from 'app/crud/certificates.crud';
import 'assets/scss/tags_select.scss';
import { downloadFile } from 'utils/fileUtils';
import BeSafeButton from 'components/commons/BeSafeButton';
import CertificateListSelect from 'components/commons/CertificateListSelect';

const CertificateSelect = (props) => {
  const [selectedTag, setSelectedTag] = useState(props.initialTag || null);
  const [showModal, setShowModal] = useState(false);

  const downloadTag = (certificateId) => {
    downloadCertificate(certificateId)
      .then((response) => {
        const { content, filename } = response.data;

        downloadFile(content, filename, 'text/plain');
      })
      .catch(() => {})
      .finally(() => {});
  };

  const handleItemChange = (item) => {
    setSelectedTag(item);
    props.handleChange(item);
  };

  useEffect(() => {
    if (props.initialTag) setSelectedTag(props.initialTag);
  }, [props.initialTag]);

  return (
    <>
      <div className="d-flex">
        <BeSafeButton
          className="flex-shrink-0 button-fixed-height"
          onClick={() => setShowModal(true)}
          icon={<WorkspacePremium className="mr-2" />}
          text={<FormattedMessage id="LIST_MODAL.CERTIFICATE_SELECT" />}
        />
        {selectedTag && (
          <div className="d-flex align-items-center ml-4">
            <Form.Label className="m-0">
              <span className="text-break">{`${selectedTag.name}, ${selectedTag.subject}`}</span>
              {selectedTag.source === 'local' && (
                <BeSafeButton
                  className="p-0 ml-2"
                  onClick={() => downloadTag(selectedTag.id)}
                  icon={<Download />}
                  variant="transparent"
                />
              )}
            </Form.Label>
          </div>
        )}
      </div>

      <CertificateListSelect
        showModal={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        changeItems={handleItemChange}
        initialSelected={props.initialTag}
      />
    </>
  );
};

export default CertificateSelect;
