import axios from "axios";

const BASE_URL = "/certificates";

export function getCertificates(params) {
  return axios.get(`${BASE_URL}`, { params });
}

export function getCertificate(id) {
  return axios.get(`${BASE_URL}/${id}`);
}

export function createCertificate(params) {
  return axios.post(`${BASE_URL}`, params);
}

export function updateCertificate(id, params) {
  return axios.patch(`${BASE_URL}/${id}`, params);
}

export function destroyCertificate(id) {
  return axios.delete(`${BASE_URL}/${id}`);
}

export function downloadCertificate(id) {
  return axios.get(`${BASE_URL}/${id}/download`);
}
