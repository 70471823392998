import React, { useState, useEffect, useCallback } from "react";
import { Modal, Table, Button, Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import _ from "lodash";
import NoDataDisplay from "components/commons/NoDataDisplay";
import TableLoader from "components/commons/TableLoader";
import BeSafeButton from "components/commons/BeSafeButton";
import BeSafeContainer from "components/commons/BeSafeContainer";
import { fetchAllApplications, fetchCategories } from "app/crud/applications.crud";
import BeSafeSearchBar from "components/commons/BeSafeSearchBar";

const ApplicationListSelect = (props) => {
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState(
    props.initialSelected || []
  );
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [selectedType, setSelectedType] = useState("All");
  const [categories, setCategories] = useState([]);

  const selectItem = (item) => {
    if (_.findIndex(selectedItems, i => { return i.id === item.id }) >= 0) {
      setSelectedItems((prev) => selectedItems.filter(si => si.id !== item.id));
    } else {
      if (props.limit !== 0 && selectedItems.length >= props.limit) {
        return;
      }
      setSelectedItems((prev) => [...prev, item]);
    }
  };

  const handleSubmit = () => {
    if (_.isFunction(props.changeItems)) {
      props.changeItems(selectedItems);
      handleHide();
    }
  };

  const handleHide = () => {
    setSelectedItems(props.initialSelected);
    setQuery("");
    props.onHide();
  };

  const getCategories = useCallback(() => {
    setLoading(true);
    fetchCategories()
    .then((response) => {
      response.data.categories.unshift("All");
      setCategories(response.data.categories);
      setSelectedType("All");
    })
    .finally(() => {
        setLoading(false);
    })
  },[]);

  const getApplications = useCallback((query) => {
      setLoading(true);
      if(selectedType !== "")
      {
        if(selectedType === "All")
        {
          const params = {
            query: query
          }
          fetchAllApplications(params)
          .then((response) => {
            setItems(response.data.all_applications);
            setLoading(false);
          })
          .finally(() => {
              setLoading(false);
          })
        } else {
          const params = {
            family_name: selectedType,
            query: query
          }
          fetchAllApplications(params)
          .then((response) => {
            setItems(response.data.all_applications);
            setLoading(false);
          })
          .finally(() => {
              setLoading(false);
          })
        }
      }
    }, [selectedType]);

  useEffect(() => {
    getApplications(query);
  }, [getApplications, query]);

  useEffect(() => {
    getCategories();
  }, [getCategories])


  useEffect(() => {
    if (props.initialSelected) setSelectedItems(props.initialSelected);
  }, [props.initialSelected]);

  const changeObject = (e) => {
    setSelectedType(e.target.value);
  }; 

  return (
    <>
      <Modal
        show
        onHide={handleHide}
        size="lg"
        centered
        dialogClassName="modal-shadow-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>        
            <FormattedMessage id={`APPLICATIONS.TITLE`} />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="pt-3">
          <div className="w-100 d-flex align-items-center">
           <div className="w-100">
              <BeSafeSearchBar
                onSearch={setQuery}
              />
          </div>
           <Form.Group className="mr-2 mb-0">
              <Form.Control
                className="w-auto"
                as="select"
                value={selectedType}
                onChange={(e) => changeObject(e)}
              >
                {categories.map((item, index) => (
                  <option key={index} value={item}>
                         {item}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            </div>
            <hr/>
          <TableLoader rows={10} visible={loading} />
          <NoDataDisplay visible={!loading && items.length <= 0} />
          <BeSafeContainer visible ={!loading && items.length > 0}>
            <div
              className="modal-selection-list overflow-auto"
            >
              <Table className="mb-0 table-borderless" hover responsive="lg">
                <tbody>
                  {items.map((item, index) => (
                    <tr key={index}>
                      <td
                        className="border-bottom"
                        onClick={() => selectItem(item)}
                      >
                        <div className="d-flex justify-content-between">
                          <Form.Group className="mb-0 d-inline-block">
                            <Form.Check
                              id={item.id}
                              className="mb-0"
                              type="checkbox"
                            >
                              <Form.Check.Input
                                type="checkbox"
                                isValid
                                value={selectedItems.includes(item.name)}
                                checked={selectedItems.map((selectedItem) => selectedItem.id).includes(item.id)}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => selectItem(item)}
                              />
                              <Form.Check.Label
                                onClick={(e) => e.stopPropagation()}
                              >
                                {item.name} - {item.family}
                              </Form.Check.Label>
                            </Form.Check>
                          </Form.Group>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </BeSafeContainer>
        </Modal.Body>
        <Modal.Footer className="py-3">
          <Button variant="transparent" onClick={props.onHide}>
            <FormattedMessage id="OBJECT_MODAL.CANCEL" />
          </Button>
          <BeSafeButton
            variant="primary"
            type="button"
            onClick={handleSubmit}
            text={<FormattedMessage id="GENERAL.SAVE" />}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ApplicationListSelect;
