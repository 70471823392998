import React, { useState, useEffect } from 'react';
import { Add } from '@mui/icons-material';
import { Cancel } from '@material-ui/icons';
import _ from 'lodash';

import 'assets/scss/tags_select.scss';
import BeSafeButton from 'components/commons/BeSafeButton';
import ApplicationListSelect from 'components/networks/ApplicationListSelect';

const ApplicationSelect = (props) => {
  const [selectedTags, setSelectedTags] = useState(props.initialTags || []);

  const [showModal, setShowModal] = useState(false);

  const removeTags = (indexToRemove) => {
    const newTags = [
      ...selectedTags.filter((_, index) => index !== indexToRemove),
    ];
    setSelectedTags(newTags);
    if (_.isFunction(props.handleChange)) props.handleChange(newTags);
  };
  const handleItemsChange = (items) => {
    setSelectedTags(items);
    props.handleChange(items);
  };

  useEffect(() => {
    const isEqual = _.isEqual(props.initialTags, selectedTags);

    if (!isEqual && props.initialTags) {
      setSelectedTags(props.initialTags);
    }
  }, [props.initialTags, selectedTags]);

  return (
    <>
      <div
        className={`d-flex ${props.disabled ? 'disabled' : ''}`}
        style={{ position: 'relative' }}
      >
        <div className="tags-select">
          <ul id="tags">
            {props.limitVisibleItems && selectedTags.length > 5 ? (
              <>
                {selectedTags?.slice(0, 5).map((tag, index) => (
                  <li key={index} className="tag">
                    <span className="ellipsable">{tag.name}</span>
                    <span onClick={() => removeTags(index)}>
                      <Cancel className="tag-close-icon" />
                    </span>
                  </li>
                ))}
                ...
              </>
            ) : (
              selectedTags?.map((tag, index) => (
                <li key={index} className="tag">
                  <span className="ellipsable">{tag.name}</span>
                  <span onClick={() => removeTags(index)}>
                    <Cancel className="tag-close-icon" />
                  </span>
                </li>
              ))
            )}
          </ul>
        </div>
        <BeSafeButton
          className="btn-primary-transparent btn-in-box"
          onClick={() => {
            if (!props.disabled) {
              setShowModal(true);
            }
          }}
          icon={<Add />}
          variant="transparent"
        />
      </div>

      {showModal && (
        <ApplicationListSelect
          onHide={() => {
            setShowModal(false);
          }}
          changeItems={handleItemsChange}
          initialSelected={props.initialTags}
          deviceId={props.deviceId}
          limit={props.limit}
        />
      )}
    </>
  );
};

ApplicationSelect.defaultProps = {
  limit: 0,
};

export default ApplicationSelect;
